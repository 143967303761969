import { createSlice } from "@reduxjs/toolkit";
import { postContactValid } from "../../reducer/contact-us/contactReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postContactValid.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postContactValid.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.concat(action.payload);
      })
      .addCase(postContactValid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default contactSlice.reducer;
