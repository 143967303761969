import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RouterPaths } from "./paths";
import { RouterPathName } from "./config";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";

function RouterApp() {
  const emailVerified = useSelector(
    (state) => state?.forgotPassword?.emailSubmitted
  );

  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={null}>
          <Routes>
            <Route path={RouterPathName.home} element={<RouterPaths.Home />} />
            <Route path={RouterPathName.faq} element={<RouterPaths.FAQ />} />
            <Route
              path={RouterPathName.login}
              element={<RouterPaths.Login />}
            />
            <Route
              path={RouterPathName.termsConditions}
              element={<RouterPaths.TermsAndConditions />}
            />
            <Route
              path={RouterPathName.mailVerification}
              element={<RouterPaths.MailVerification />}
            />

            <Route
              path={RouterPathName.broker}
              element={<RouterPaths.Broker />}
            />
            <Route
              path={RouterPathName.pricing}
              element={<RouterPaths.Pricing />}
            />
            <Route
              path={RouterPathName.registerAccount}
              element={<RouterPaths.RegisterAccount />}
            />
            <Route
              path={RouterPathName.setupAccount}
              element={<RouterPaths.SetupAccount />}
            />
            <Route
              path={RouterPathName.googleSetupAccount}
              element={<RouterPaths.GoogleSetupAccount />}
            />
            <Route
              path={RouterPathName.contactUs}
              element={<RouterPaths.ContactUs />}
            />
            <Route
              path={RouterPathName.aboutUs}
              element={<RouterPaths.AboutUs />}
            />
            <Route
              path={RouterPathName.resetPassword}
              element={
                emailVerified ? (
                  <RouterPaths.ResetPassword />
                ) : (
                  <RouterPaths.ForgotPassword />
                )
              }
            />
            <Route
              path={RouterPathName.forgotPassword}
              element={<RouterPaths.ForgotPassword />}
            />
            <Route
              path={RouterPathName.features}
              element={<RouterPaths.Features />}
            />
            <Route
              path={RouterPathName.tradeGuru}
              element={<RouterPaths.TradeGuru />}
            />
            <Route
              path={RouterPathName.traders}
              element={<RouterPaths.Traders />}
            />
            <Route
              path={RouterPathName.privacyPolicy}
              element={<RouterPaths.PrivacyPolicy />}
            />
            <Route
              path={RouterPathName.notfound}
              element={<RouterPaths.NotFound />}
            />
            <Route
              path={RouterPathName.twoFactorOtp}
              element={<RouterPaths.TwoFactorOtp />}
            />
            <Route
              path={RouterPathName.dashboardTermsConditions}
              element={<RouterPaths.DashboardTermsConditions />}
            />
            <Route
              path={RouterPathName.dashboardPrivacyPolicy}
              element={<RouterPaths.DashboardPrivacyPolicy />}
            />

            {/* Protected Routes  */}

            <Route element={<ProtectedRoute />}>
              <Route
                path={RouterPathName.dashboard}
                element={<RouterPaths.Dashboard />}
              />
              <Route
                path={RouterPathName.marketPlace}
                element={<RouterPaths.MarketPlace />}
              />
              <Route
                path={RouterPathName.purchasedStrategy}
                element={<RouterPaths.PurchasedStrategy />}
              />
              <Route
                path={RouterPathName.tradingPlans}
                element={<RouterPaths.TradingPlans />}
              />
              <Route
                path={RouterPathName.managedStrategies}
                element={<RouterPaths.ManagedStrategies />}
              />
              <Route
                path={RouterPathName.pricingPlans}
                element={<RouterPaths.PricingPlans />}
              />

              <Route
                path={RouterPathName.chooseBroker}
                element={<RouterPaths.ChooseBroker />}
              />
              <Route
                path={RouterPathName.reviewBrokerDisclaimer}
                element={<RouterPaths.ReviewBrokerDisclaimer />}
              />
              <Route
                path={RouterPathName.currentBrokerAccount}
                element={<RouterPaths.CurrentBrokerAccount />}
              />
              <Route
                path={RouterPathName.chooseLivePaperAccount}
                element={<RouterPaths.ChooseLivePaperAccount />}
              />
              <Route
                path={RouterPathName.accountSettings}
                element={<RouterPaths.AccountSettings />}
              />
              <Route
                path={RouterPathName.provideCredential}
                element={<RouterPaths.ProvideCredential />}
              />
              <Route
                path={RouterPathName.tradeHistory}
                element={<RouterPaths.TradeHistory />}
              />
              <Route
                path={RouterPathName.pricingPlansPayment}
                element={<RouterPaths.PricingPlanPayment />}
              />
              <Route
                path={RouterPathName.setToken}
                element={<RouterPaths.SetToken />}
              />
              <Route
                path={RouterPathName.profile}
                element={<RouterPaths.ProfileSettings />}
              />
              <Route
                path={RouterPathName.changePassword}
                element={<RouterPaths.ChangePassword />}
              />
              <Route
                path={RouterPathName.ViewConnectedBrokerDetails}
                element={<RouterPaths.ViewConnectedBrokerDetails />}
              />
              <Route
                path={RouterPathName.ViewTradingPlanSummary}
                element={<RouterPaths.ViewTradingPlanSummary />}
              />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default RouterApp;
