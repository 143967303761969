import React, { createContext, useState, useContext, useEffect } from 'react';

const CookieConsentContext = createContext();

export const CookieConsentProvider = ({ children }) => {
    const [cookieConsent, setCookieConsent] = useState(null);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent) {
            setCookieConsent(consent === 'true');
        }
    }, []);

    const handleAcceptCookies = () => {
        setCookieConsent(true);
        localStorage.setItem('cookieConsent', 'true');
    };

    const handleDeclineCookies = () => {
        setCookieConsent(false);
        localStorage.setItem('cookieConsent', 'false');
    };

    return (
        <CookieConsentContext.Provider value={{ cookieConsent, handleAcceptCookies, handleDeclineCookies }}>
            {children}
        </CookieConsentContext.Provider>
    );
};

export const useCookieConsent = () => {
    return useContext(CookieConsentContext);
};
