import { createAsyncThunk } from "@reduxjs/toolkit";
import { SUBSCRIPTION_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const fetchSubscriptions = createAsyncThunk(
  SUBSCRIPTION_ACTIONS.fetch_subscription,
  async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + APP_PATH.subscription
      );
      return response.data.response;
    } catch (error) {
      toast.error("Something went wrong");
      throw error;
    }
  }
);

export const clearSubscription = createAsyncThunk(
  SUBSCRIPTION_ACTIONS.clear_subscription,
  () => {
    return [];
  }
);
