export const days = [
	{ id: 1, name: "Mon" },
	{ id: 2, name: "Tue" },
	{ id: 3, name: "Wed" },
	{ id: 4, name: "Thu" },
	{ id: 5, name: "Fri" },
	{ id: 6, name: "Sat" },
	{ id: 7, name: "Sun" },
];

export const expirationInputTypes = {
	date: {
		type: "date",
		placeholder: "Date",
		className: "set-feild",
		name: "date",
	},
	days: {
		type: "text",
		placeholder: "Enter No Of Days",
		className: "set-feild",
		name: "days",
	},
	weeks: {
		type: "text",
		placeholder: "Enter No Of Weeks",
		className: "set-feild",
		name: "weeks",
	},
	month: {
		type: "text",
		placeholder: "Enter No Of Months",
		className: "set-feild",
		name: "month",
	},
	year: {
		type: "text",
		placeholder: "Enter No Of Years",
		className: "set-feild",
		name: "year",
	},
};

export const sortByOptions = [
	{ id: 1, name: "sortBy", value: "latest", label_name: "Latest" },
	{ id: 2, name: "sortBy", value: "minFees", label_name: "Min Fees" },
	{ id: 3, name: "sortBy", value: "minCapital", label_name: "Min Capital" },
];

export const monthlyFee = [
	{ id: 1, name: "monthlyFee", value: "Free", label_name: "Free" },
	{ id: 2, name: "monthlyFee", value: "Paid", label_name: "Paid" },
];

export const tags = [
	{ id: 1, tag_name: "Directional", value: "Directional" },
	{ id: 2, tag_name: "Day Trading", value: "Day Trading" },
	{ id: 3, tag_name: "Exit - Provided", value: "Exit - Provided" },
	{ id: 4, tag_name: "Non-Directional", value: "Non-Directional" },
	{ id: 5, tag_name: "Buy", value: "Buy" },
	{ id: 6, tag_name: "Sell-Short", value: "Sell-Short" },
	{ id: 7, tag_name: "Reverses Direction", value: "Reverses Direction" },
	{ id: 8, tag_name: "Swing Trading", value: "Swing Trading" },
];

export const assetClassName = [
	{ id: 1, asset_name: "Stocks", value: "Stocks" },
	{ id: 2, asset_name: "Options", value: "Options" },
	{ id: 3, asset_name: "Futures (coming soon)", value: "Futures" },
	{ id: 4, asset_name: "Crypto (coming soon)", value: "Crypto" },
];

export const tradeItems = [
	{
		id: "basic",
		imgSrc: require("../assets/images/trade-set1.png"),
		label: "Basic",
	},
	{
		id: "notifications",
		imgSrc: require("../assets/images/trade-set2.png"),
		label: "Notifications",
	},
	{
		id: "option",
		imgSrc: require("../assets/images/Option1.png"),
		label: "Options",
	},
	{
		id: "tradingWindow",
		imgSrc: require("../assets/images/trade-set3.png"),
		label: "Trading Window",
	},
	{
		id: "positionSize",
		imgSrc: require("../assets/images/trade-set4.png"),
		label: "Position Size",
	},
	{
		id: "takeProfit",
		imgSrc: require("../assets/images/trade-set5.png"),
		label: "Take Profit",
	},
	{
		id: "stopLoss",
		imgSrc: require("../assets/images/trade-set6.png"),
		label: "Stop Loss",
	},
	{
		id: "entry",
		imgSrc: require("../assets/images/trade-set7.png"),
		label: "Entry",
	},
	{
		id: "exit",
		imgSrc: require("../assets/images/trade-set8.png"),
		label: "Exit",
	},
	{
		id: "delay",
		imgSrc: require("../assets/images/trade-set3.png"),
		label: "Delay",
	},
	{
		id: "retry",
		imgSrc: require("../assets/images/trade-set11.png"),
		label: "Retry",
	},
];

export const optionType = [
	{ id: 1, option_type_name: "Select option type", value: "" },
	{
		id: 2,
		option_type_name: "Both - Long Calls on Bullish & Long Puts on Bearish",
		value: "both",
	},
	{
		id: 3,
		option_type_name: "Call - Long Calls on Bullish & Short Calls on Bearish",
		value: "call",
	},
	{
		id: 4,
		option_type_name: "Put - Short Puts on Bullish & Long Puts on Bearish",
		value: "put",
	},
];

export const instrinsicList = [
	{ id: 1, intrinsic_name: "Select intrinsic value", value: "" },
	{ id: 2, intrinsic_name: "In The Money", value: "itm" },
	{ id: 3, intrinsic_name: "Out Of The Money", value: "otm" },
];

export const expirationTypeList = [
	{ id: 1, expiration_type: "Select expiration type", value: "" },
	{ id: 2, expiration_type: "Date", value: "date" },
	{ id: 3, expiration_type: "Days", value: "days" },
	{ id: 4, expiration_type: "Weeks", value: "weeks" },
	{ id: 5, expiration_type: "Months", value: "month" },
	{ id: 6, expiration_type: "Years", value: "year" },
];

export const descriptions = {
	subscription_name:
		"Give your subscription a name that would be useful to you. It can be anything and will only be seen by you. (e.g., Reversal Scalp)",
	emails_to_notify:
		"Enter a list of email addresses to notify of new trades and failed trades. Multiple email addresses can be separated by commas. This setting overrides your notification settings in Account Settings.",
	notify_failures_only:
		"Check this if you only want to be notified of failed trades.",
	notify_of_any_trade:
		"Enable this setting to be notified of every trade signal, even if your Trading Plan is disabled.Useful if you want to enterthe trade manually.",
	option_type:
		"Choose what option type to use. Both will use long calls on bullish and long puts on bearish. Call will use long calls on bullish and short calls on bearish. Put will use short puts on bullish and long puts on bearish.",
	intrinsic_value:
		"Whether you want the option to be in the money or out of the money.Default is in the money (ITM).",
	expiration: `How far out in expiration at a minimum you want to go. Example, select a “date” to filter out anything before that date, or "week" and “1” to filteranything less than 1 week expiration away from now, or “months" and “2” to filter anything less than 2 months expiration away from now. Valid intervals are days, weeks, months, and years. Leave blank to select the nearest expiration date.`,
	strike_count: `The number of strike prices to consider when scanning the option chain. [Min:2 | Max: 20 | Default: 12]. (Note: The more strikes you consider when scanning the option chain, the slower your trade execution will be.)`,
	strike_away: `The number of strike prices away from at-the-money to select. If you leave this blank it will select the first contract.`,
	use_signal_strike_price: `If the trade signal has a contract defined, use that contract instead of calculating your own contract. You can learn more about how to send trade signals in our documentation.`,
	use_signal_contract: `If the trade signal has a contract defined, use that contract instead of calculating your own contract. You can learn more about how to send trade signals in our documentation.`,
	trading_window_timezone: `Timezone to use for trading windows. Defaults to UTC.`,
	add_trading_window: `Configure your allowed trading window days and times. The "from" time is inclusive and the "to" time is exclusive.`,
	dynamic_quantity: `Choose which dynamic quantity calculation method you want to use.`,
	substract_exit_quantity: `Check this if you want the exit quantity to be subtracted from the signal quantity and use the remainder as the entry quantity.`,
	allow_add_position: `Allow adding to an existing open position. If this is not checked, trades will be rejected if there is already an open position.`,
	take_profit_percent: `Enter a take profit percent to include a take profit order leg in your order.`,
	take_profit_amount: `Enter a take profit dollar amount to include a take profit order leg in your order.`,
	daily_take_profit: `Daily Take Profit allows you to set a different take profit for the day of the week, or even skip a trade on that day.`,
	stop_loss_type: `Choose what kind of stop loss order you want attached to your entry order.`,
	stop_loss_percentage: `Enter a stop loss percent to include a stop loss order leg in your order.`,
	stop_loss_amount: `Enter a stop loss dollar amount to include a stop loss order leg in your order.`,
	use_signal_stop_loss: `If the trade signal has a stop loss defined, use that stop loss instead of using the configured stop loss.`,
	entry_order_type: `Choose the order type to use for entry orders. If you select "Market" and also check "Allow entry extended hours," then market orders will be used when the market is open and extended hours limit orders when closed.`,
	use_signal_order_type_for_entries: `If the trade signal has an order type defined, use that order type for entries instead of calculating your own entry order type.`,
	entry_time_in_force: `Choose the time in force for entry orders.`,
	use_signal_time_in_force: `If the trade signal has a time in force defined, use that time in force for entries instead of calculating your own entry time in force.`,
	entry_market_price_type: `Choose what quote price to use as the current market price for entries if the signal does not have a price.[Default: "Bid-ask midpoint"]`,
	use_signal_price_for_entries: `If the trade signal has a price defined, use that price for entries instead of calculating your own entry price. The bid-ask price will be used by default.`,
	exit_order_type: `Choose the order type to use for exit orders. If you select "Market" and also check "Allow entry extended hours," then market orders will be used when the market is open and extended hours limit orders when closed.`,
	use_signal_time_in_force_exit: `If the trade signal has a time in force defined, use that time in force for exits instead of calculating your own exit time in force.`,
	exit_time_in_force: `Choose the time in force for exit orders.`,
	use_signal_time_in_force_for_exits: `Exit orders will be sent as extended hours limit orders. If you check "Exit market," extended hours limit orders will be used when the market is closed, and market orders when open.`,
	exit_market_price: `Choose what quote price to use as the current market price for exits if the signal does not have a price.[Default: "Bid-ask midpoint"]`,
	user_signal_price_for_exits: `Exit orders will be sent as extended hours limit orders. If you check "Exit market," extended hours limit orders will be used when the market is closed and market orders when open.`,
	exit_wait_timeout: `Number of seconds to wait for an exit order to fill before submitting the entry order. (Ignored if no entry order exists.) [Min: 60 | Max: 300 | Default: 120]`,
	delay: `Choose the delay for the order execution. This will determine the time to wait before placing an order.`,
	cancel_wait_timeout: `Number of seconds to wait for an order to cancel before submitting the exit or entry order. (Ignored if no exit or entry order exists, or there are no open orders to cancel.) [Min: 60 | Max: 300 | Default: 120]`,
	retry_trades: `Retry trades that failed during trade planning. If a trade fails during execution after trade planning, the trade will not be retried.`,
	max_retries: `Number of times to retry a trade that failed during trade planning. [Min: 1 | Max: 5 | Default: 5]`,
	delay_in_milliseconds: `Number of milliseconds before retrying a trade that failed during trade planning. [Min: 1000 | Max: 10000 | Default:1000]`,
	delay_multiplier: `How much to multiply the delay by after each retry. [Min:1 | Max: 3 | Default: 2]`,
	max_delay: `The maximum delay in milliseconds to wait before retrying a trade that failed during trade planning. [Min: 1000 | Max: 60000 | Default: No max]`,

	// Add other descriptions as needed
};

export const dynamicQuantityList = [
	{ id: 1, quantity_name: "Select dynamic quantity", value: "" },
	{ id: 2, quantity_name: "Fixed quantity", value: "fixed_quantity" },
	{ id: 3, quantity_name: "Amount per position", value: "amount_per_position" },
	{ id: 4, quantity_name: "Risk per position", value: "risk_per_position" },
	{ id: 5, quantity_name: "Percent of equity", value: "percent_of_equity" },
];

export const stopLossType = [
	{ id: 1, stop_loss_type_name: "Select stop loss type", value: "" },
	{ id: 2, stop_loss_type_name: "Stop Market", value: "stop_market" },
	{ id: 3, stop_loss_type_name: "Stop Limit", value: "stop_limit" },
	{ id: 4, stop_loss_type_name: "Trailing Stop", value: "trailing_stop" },
];

export const entryMarketPriceType = [
	{
		id: 1,
		entry_market_price_type_name: "Select entry market price",
		value: "",
	},
	{
		id: 2,
		entry_market_price_type_name: "Bid-ask midpoint",
		value: "bid_ask_midpoint",
	},
	{
		id: 3,
		entry_market_price_type_name: "Always use ask",
		value: "always_use_ask",
	},
	{
		id: 4,
		entry_market_price_type_name: "Always use bid",
		value: "always_use_bid",
	},
	{
		id: 5,
		entry_market_price_type_name: "Use last price",
		value: "use_last_price",
	},
];

export const entryTimeInForce = [
	{ id: 1, entry_time_in_force_name: "Select entry time in force", value: "" },
	{ id: 2, entry_time_in_force_name: "Good For Day", value: "day" },
	{ id: 3, entry_time_in_force_name: "Good Until Canceled", value: "gtc" },
];

export const entryOrderType = [
	{ id: 1, entry_order_type_name: "Select entry order type", value: "" },
	{ id: 2, entry_order_type_name: "Market", value: "market" },
	{ id: 3, entry_order_type_name: "Limit", value: "limit" },
];

export const exitOrderType = [
	{ id: 1, exit_order_type_name: "Select exit order type", value: "" },
	{ id: 2, exit_order_type_name: "Stop Market", value: "stop_market" },
	{ id: 3, exit_order_type_name: "Stop Limit", value: "stop_limit" },
];

export const exitTimeInForce = [
	{ id: 1, exit_time_in_force_name: "Select exit time in force", value: "" },
	{ id: 2, exit_time_in_force_name: "Good For Day", value: "day" },
	{ id: 3, exit_time_in_force_name: "Good Until Canceled", value: "gtc" },
];

export const exitMarketPriceType = [
	{
		id: 1,
		exit_market_price_type_name: "Select exit market price type",
		value: "",
	},
	{
		id: 2,
		exit_market_price_type_name: "Bid-ask midpoint",
		value: "bid_ask_midpoint",
	},
	{
		id: 3,
		exit_market_price_type_name: "Always use ask",
		value: "always_use_ask",
	},
	{
		id: 4,
		exit_market_price_type_name: "Always use bid",
		value: "always_use_bid",
	},
	{
		id: 5,
		exit_market_price_type_name: "Use last price",
		value: "use_last_price",
	},
];

export const delay = [
	{ id: 1, delay_name: "Select delay", value: "" },
	{ id: 2, delay_name: "0 Seconds", value: "0" },
	{ id: 3, delay_name: "1 Seconds", value: "1" },
	{ id: 4, delay_name: "2 Seconds", value: "2" },
	{ id: 5, delay_name: "3 Seconds", value: "3" },
];

export const navNotification = [
	{
		id: 1,
		img: require("../assets/images/dashboard-images/chat-1.png"),
		name: "Petey Cruiser",
		message: " I'm sorry but i'm not sure how to help you with that......",
		Time: "Mar 15 3:55 PM",
	},
	{
		id: 2,
		img: require("../assets/images/dashboard-images/chat-2.png"),
		name: "Jimmy Changa",
		message: "All set ! Now, time to get to you now......",
		Time: "Mar 06 01:12 AM",
	},
	{
		id: 3,
		img: require("../assets/images/dashboard-images/chat-3.png"),
		name: "Graham Cracker",
		message: "Are you ready to pickup your Delivery...",
		Time: "Feb 25 10:35 AM",
	},
	{
		id: 4,
		img: require("../assets/images/dashboard-images/chat-4.png"),
		name: "Donatella Nobatti",
		message: " Here are some products ...",
		Time: "Feb 12 05:12 PM",
	},
	{
		id: 5,
		img: require("../assets/images/dashboard-images/chat-5.png"),
		name: "Anne Fibbiyon",
		message: "I'm sorry but i'm not sure how...",
		Time: "Jan 29 03:16 PM",
	},
];

export const fieldsToClear = {
	fixed_quantity: [
		"amount_per_position",
		"risk_per_position",
		"percent_of_equity",
	],
	amount_per_position: [
		"fixed_quantity",
		"risk_per_position",
		"percent_of_equity",
	],
	risk_per_position: [
		"fixed_quantity",
		"amount_per_position",
		"percent_of_equity",
	],
	percent_of_equity: [
		"fixed_quantity",
		"amount_per_position",
		"risk_per_position",
	],
};

export const VALIDATION_MESSAGES = {
	NAME_REQUIRED: 'You must enter a value for the "Name" for basic settings.',
	NAME_LENGTH:
		'You must enter a value for the "Name" at least 3 characters long.',
	ENTRY_ORDER_TYPE_REQUIRED:
		'You must enter a value for the "Entry Order Type".',
	EXIT_ORDER_TYPE_REQUIRED:
		'You must choose a value for the "Exit Order Type".',
	EXIT_TIME_IN_FORCE_REQUIRED:
		'You must choose a value for the "Exit Time In Force".',
	EXIT_MARKET_PRICE_TYPE_REQUIRED:
		'You must choose a value for the "Exit Price Type".',
	FIXED_QUANTITY_REQUIRED:
		'You must enter a value for the "Fixed quantity" quantity calculation method.',
	AMOUNT_PER_POSITION_REQUIRED:
		'You must enter a value for the "Amount per position" quantity calculation method.',
	RISK_PER_POSITION_REQUIRED:
		'You must enter a value for the "Risk per position" quantity calculation method.',
	PERCENT_OF_EQUITY_REQUIRED:
		'You must enter a value for the "Percent of equity" quantity calculation method.',
	DELAY_REQUIRED: `You must choose a value for the "Delay".`,
	DAYS_REQUIRED: `You must choose a value for the "Days"`,
	WEEKS_REQUIRED: `You must choose a value for the "Weeks"`,
	MONTHS_REQUIRED: `You must choose a value for the "Months"`,
	YEARS_REQUIRED: `You must choose a value for the "Years"`,
	DATE_REQUIRED: `You must choose a value for the "Date"`,
	OPTION_TYPE_REQUIRED: `You must choose a value for the "Option Type"`,
	STRIKE_AWAY_REQUIRED: `You must enter a value for the "Strike Away"`,
	STRIKE_COUNT_REQUIRED: `You must enter a value for the "Strike Count" [Min:2 | Max: 20 | Default: 12]`,
	STRIKE_COUNT_RANGE: `Strike count must be between 2 and 20.`,
	STRIKE_AWAY_NEGATIVE: `You cannot enter 0 or a negative value for the "Strike Away".`,
	NEGATIVE_FIXED_QUANTITY: `You cannot enter a negative value for the "Fixed Quantity" quantity calculation method.`,
	NEGATIVE_AMOUNT_PER_POSITION: `You cannot enter a negative value for the "Amount Per Position" quantity calculation method.`,
	NEGATIVE_RISK_PER_POSITION: `You cannot enter a negative value for the "Amount Per Position" quantity calculation method.`,
	NEGATIVE_PERCENT_OF_EQUITY: `You cannot enter a negative value for the "Percent Of Equity" quantity calculation method.`,
	STOPLOSS_TYPE_REQUIRED: `Choose a valid stop loss type.`,
	STOP_AMOUNT_PERCENT_REQUIRED: `Enter a valid stop loss percent or stop loss amount.`,
	NEGATIVE_TAKE_PROFIT_PERCENTAGE: `Take profit percent must be greater than`,
	TAKE_PROFIT_PERCENTAGE_RANGE: `Take profit percent must be between "0.01" and "10000"`,
	NEGATIVE_TAKE_PROFIT_AMOUNT: `You cannot enter a negative value for the "Take Profit Amount".`,
};

export const POSITION_SIZE_TYPES = {
	FIXED_QUANTITY: "fixed_quantity",
	AMOUNT_PER_POSITION: "amount_per_position",
	RISK_PER_POSITION: "risk_per_position",
	PERCENT_OF_EQUITY: "percent_of_equity",
};

export const ASSET_CLASSES = {
	STOCKS: "stocks",
	OPTIONS: "options",
};

export const STRATEGY_USE_CASES = {
	ENTRY_ONLY: "entry_only",
	ENTRY_AND_EXIT: "entry_and_exit",
};

export const STRATEGY_TYPE = {
	ENTRY_ONLY: 'Entry Only',
	ENTRY_AND_EXIT: 'Entry & Exit'
}

export const DEFAULT_DAILY_TAKE_PROFIT_LIST = [
	{ days: "Mon", percentage: 0 },
	{ days: "Tue", percentage: 0 },
	{ days: "Wed", percentage: 0 },
	{ days: "Thu", percentage: 0 },
	{ days: "Fri", percentage: 0 },
	{ days: "Sat", percentage: 0 },
	{ days: "Sun", percentage: 0 },
];

export const DEFAULT_FORM_DATA = {
	strategy: null,
	name: "",
	email_notify: "",
	failure_email_notify: false,
	notify_me_for_any_trade: false,
	option: "both",
	intrinsic_values: "",
	expiration_type: "",
	expiration: "",
	strike_count: "",
	strike_away: "",
	time_zone: "",
	trading_window_status: "",
	position_size_type: "",
	quantity: "",
	amount_per_position: "",
	risk_per_position: "",
	percent_of_equity: "",
	use_signal_quantity: false,
	daily_take_profit: false,
	daily_take_profit_list: [],
	take_profit_percentage: "",
	take_profit_amount: "",
	stop_loss_type: "",
	stop_loss_percentage: "",
	stop_loss_amt: "",
	entry_order_type: "limit",
	entry_time_in_force: "",
	entry_market_price_type: "",
	entry_signal_order_type: false,
	use_signal_price_entry: false,
	exit_order_type: "stop_limit",
	exit_time_in_force: "",
	exit_market_price_type: "",
	use_signal_price_exit: false,
	delay: "0",
	retry_trade: false,
	max_retry: "",
	delay_in_milliseconds: "",
	delay_multiplier: "",
	max_delay_in_milliseconds: "",
	broker_account: null,
	days: "",
	weeks: "",
	month: "",
	year: "",
	date: "",
};

export const DEFAULT_STATE = {
	basic: true,
	notifications: false,
	tradingWindow: false,
	positionSize: false,
	takeProfit: false,
	stopLoss: false,
	entry: false,
	exit: false,
	delay: false,
	cancel: false,
	retry: false,
	options: false,
	all: false,
};

export const setup = [
	{
		id: 1,
		heading: "Connect Broker",
		content: "Connect a paper or live broker account.",
		img: require("../assets/images/connectBroker.svg").default,
	},
	{
		id: 2,
		heading: "Create strategy",
		content: "Get started by creating a new strategy.",
		img: require("../assets/images/createStrategy.svg").default,
	},
	{
		id: 3,
		heading: "SUBSCRIBE TO STRATEGY",
		content: "Subscribe to a strategy to connect it to your broker.",
		img: require("../assets/images/SubscribeStrategy.svg").default,
	},
	{
		id: 4,
		heading: "ENABLE STRATEGY SUBSCRIPTION",
		content: "To go live, you must enable your strategy subscription.",
		img: require("../assets/images/enableStrategy.svg").default,
	},
	{
		id: 5,
		heading: "SEND ALERT WEBHOOK",
		content: "Send us an alert webhook and execute a trade.",
		img: require("../assets/images/sendAlert.svg").default,
	},
	{
		id: 6,
		heading: "UPGRADE",
		content: "Upgrade to a paid plan for live auto trading.",
		img: require("../assets/images/upgrade.svg").default,
	},
];

export const sidebar = [
	{ id: 1, path: "/dashboard", name: "Dashboard", img: require("../assets/images/dashboard-images/dashboardSidebar.svg").default, },
	{ id: 2, path: "/market-place", name: "Market Place", img: require("../assets/images/dashboard-images/marketplaceSidebar.svg").default, },
	{ id: 3, path: "/purchased-strategy", name: "Purchased Strategies", img: require("../assets/images/dashboard-images/purchasedSidebar.svg").default, },
	{ id: 4, path: "/trading-plans", name: "Trading Plans", img: require("../assets/images/dashboard-images/TradingSidebar.svg").default, },
	{ id: 5, path: "/current-broker-accounts", name: "Brokers", img: require("../assets/images/dashboard-images/brokerSidebar.svg").default, },
	{ id: 6, path: "/pricing-plans", name: "Pricing plan", img: require("../assets/images/dashboard-images/pricingPlanSidebar.svg").default, },
	{ id: 7, path: "/trade-history", name: "Trade History", img: require("../assets/images/dashboard-images/tradeHistorySidebar.svg").default, },
	{ id: 8, path: "/account-settings", name: "Account Settings", img: require("../assets/images/dashboard-images/accountSidebar.svg").default, },
]


export const moreInfoContent = [
	{
		title: 'BASIC SETTINGS',
		value: 'basic settings',
		short_description: 'Name can be anything and will only be seen by you',
	},
	{
		title: 'EMAILS TO NOTIFY',
		value: 'email to notify',
		short_description: 'Enter a list of email addresses to notify of new trades and failed trades. Multiple email addresses can be separated by commas.',
	},
	{
		title: 'NOTIFY FAILURES ONLY',
		value: 'notify failures only',
		short_description: 'Check this if you only want to be notified of failed trades.',
	},
	{
		title: 'TRADING WINDOW TIMEZONE',
		value: 'trading window',
		short_description: 'Timezone to use for trading windows. Defaults to UTC.',
	},
	{
		title: 'TAKE PROFIT PERCENT',
		value: 'take profit percent',
		short_description: 'Enter a take profit percent to include a take profit order leg in your order.',
	},
	{
		title: 'TAKE PROFIT AMOUNT',
		value: 'take profit amount',
		short_description: 'Enter a take profit dollar amount to include a take profit order leg in your order.',
	},
	{
		title: 'STOP LOSS PERCENT',
		value: 'stop loss percent',
		short_description: 'Enter a stop loss percent to include a stop loss order leg in your order. This percentage is relative to the market price.',
	},
	{
		title: 'STOP LOSS AMOUNT',
		value: 'stop loss amount',
		short_description: 'Enter a stop loss dollar amount to include a stop loss order leg in your order. This dollar amount is relative to the market price.',
	},
	{
		title: 'RETRY TRADES THAT FAIL DURING TRADE PLANNING',
		value: 'retry trade that fail',
		short_description: 'Retry trades that failed during trade planning. If a trade fails during execution after trade planning, the trade will not be retried.',
	},
	{
		title: 'MAX RETRIES',
		value: 'max retries',
		short_description: 'Number of times to retry a trade that failed during trade planning. [Min: 1 | Max: 5 | Default: 5]',
	},
	{
		title: 'DELAY IN MILLISECONDS',
		value: 'delay in milliseconds',
		short_description: 'Number of milliseconds before retrying a trade that failed during trade planning. [Min: 1000 | Max: 10000 | Default: 1000]',
	},
	{
		title: 'DELAY MULTIPLIER',
		value: 'delay multiplier',
		short_description: 'How much to multiply the delay by after each retry. [Min: 1 | Max: 3 | Default: 2]',
	},
	{
		title: 'MAX DELAY IN MILLISECONDS',
		value: 'max delay in milliseconds',
		short_description: 'The maximum delay in milliseconds to wait before retrying a trade that failed during trade planning. [Min: 1000 | Max: 60000 | Default: No max]',
	},
	{
		title: 'QUANTITY CALCULATION METHOD',
		value: 'quantity calculation method',
		short_description: 'Choose which quantity calculation method you want to use.',
	},
	{
		title: 'STOP LOSS TYPE',
		value: 'Stop loss type',
		short_description: 'Choose what kind of stop loss order you want attached to your entry order.',
	},
	{
		title: 'ENTRY ORDER TYPE',
		value: 'entry order type',
		short_description: 'Choose the order type to use for entry orders. If you select "Market" and also check "Allow entry extended hours" then market orders will be used when the market is open and extended hours limit orders will be used when the market is closed.',
	},
	{
		title: 'ENTRY TIME IN FORCE',
		value: 'entry time in force',
		short_description: 'Choose the time in force for entry orders.',
	},
	{
		title: 'ENTRY MARKET PRICE',
		value: 'entry market price',
		short_description: 'Choose what quote price to use as the current market price for entries if the signal does not have a price.',
	},
	{
		title: 'OPTION TYPE',
		value: 'option type',
		short_description: 'Choose what option type to use. Both will use long calls on bullish and long puts on bearish. Call will use long calls on bullish and short calls on bearish. Put will use short puts on bullish and long puts on bearish.',
	},
	{
		title: 'EXPIRATION',
		value: 'expiration',
		short_description: 'How far out in expiration at a minimum you want to go. Example "+1 year" to filter anything less than 1 year expiration away from now or "+3 months" to filter anything less than 3 months expiration away from now. Valid intervals are days, weeks, months and years.',
	},
	{
		title: 'USE SIGNAL STRIKE PRICE',
		value: 'use signal strike price',
		short_description: 'If the trade signal has a contract defined, use that contract instead of calculating your own contract.',
	},
	{
		title: 'STRIKE COUNT',
		value: 'strike count',
		short_description: 'The number of strike prices to consider when scanning the option chain. [Min: 2 | Max: 20 | Default: 12]. (Note: The more strikes you consider when scanning the option chain, the slower your trade execution will be.)',
	},
	{
		title: 'STRIKE AWAY',
		value: 'strike away',
		short_description: 'The number of strike prices away from at-the-money to select. If you leave this blank it will select the first contract.',
	},
	{
		title: 'INTRINSIC VALUE',
		value: 'intrinsic value',
		short_description: 'The number of strike prices away from at-the-money to select. If you leave this blank it will select the first contract.',
	},
	{
		title: 'EXIT ORDER TYPE',
		value: 'exit order type',
		short_description: 'Choose the order type to use for exit orders. If you select "Market" and also check "Allow exit extended hours" then market orders will be used when the market is open and extended hours limit orders will be used when the market is closed.',
	},
	{
		title: 'EXIT TIME IN FORCE',
		value: 'exit time in force',
		short_description: 'Choose the time in force for exit orders.',
	},
	{
		title: 'EXIT MARKET PRICE',
		value: 'exit market price',
		short_description: 'Choose what quote price to use as the current market price for exits if the signal does not have a price.',
	},
	{
		title: 'DELAY',
		value: 'delay',
		short_description: 'Choose the delay for the order execution. This will determine the time to wait before placing an order',
	},
	{
		title: 'NOTIFY ME OF ANY TRADE SIGNAL',
		value: 'notify me of any trade signal',
		short_description: 'Enable this setting to be notified of every trade signal, even if your Trading Plan is disabled.Useful if you want to enterthe trade manually',
	},
];

export const stepperDetails = [
	{
		count: 1,
		title: "Current Broker Accounts"
	},
	{
		count: 2,
		title: "Choose Broker"
	},
	{
		count: 3,
		title: "Review Disclaimers"
	},
	{
		count: 4,
		title: "Provide Credentials"
	},
	{
		count: 5,
		title: "Choose Live/Paper Account"
	}
];

export const STEPS = {
	first_step: 'Current Broker Accounts',
	second_step: 'Choose Broker',
	third_step: 'Review Disclaimers',
	fourth_step: 'Provide Credentials',
	fifth_step: 'Choose Live/Paper Account'
};

export const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};

export const assetData = [
	{ asset: 'Stocks', supported: true },
	{ asset: 'Futures', supported: false },
	{ asset: 'Options', supported: true },
	{ asset: 'Crypto', supported: false },
	{ asset: 'Forex', supported: false },
];

export const getFeatures = (brokerName) => [
	{
		feature: "Live Money Trading",
		description: "Supports trading with real live money.",
		supported: true,
		isError: false,
	},
	{
		feature: "Paper Money Trading",
		description: "Supports trading with paper money.",
		supported: true,
		isError: false,
	},
	{
		feature: "Auto Submit",
		description: "Supports submitting orders automatically to your broker.",
		supported: true,
		isError: false,
	},
	{
		feature: "Both Sides",
		description:
			"Supports both sides so you can open both long and short positions.",
		supported: true,
		isError: false,
	},
	{
		feature: "Trailing Stops",
		description:
			"Supports manually submitting trailing stop orders for open positions",
		supported: brokerName !== "Tradier",
		isError: brokerName === "Tradier",
	},
];

export const paragraphs = [
	`Hands-Free offers the powerful ability to fully automate the execution of trades through webhooks connecting your broker and exchange accounts. It's important to keep in mind that doing so may come with certain risks. While experienced traders are likely to possess the requisite knowledge and expertise to use TradersPost safely, it's important to note that not all users may be able to do so effectively. Regardless of your level of expertise, it's essential to exercise caution when automating trades through Hands-Free and be aware of any potential risks that may arise.`,
  
	`BY USING HANDS-FREE TRADING, YOU HEREBY ACKNOWLEDGE AND CONFIRM THAT YOU ARE AWARE OF THE INHERENT RISKS INVOLVED IN USING HANDS-FREE AND WILLINGLY ACCEPT THESE RISKS.`,
  
	`By using Hands-Free Trading, you agree to absolve the company, its parent company, affiliated businesses, and employees from any and all claims, demands, suits, causes of action, liabilities, costs, losses, expenses, and damages that may arise from or be related to your use of Hands-Free, including but not limited to any issues arising from errors, malfunctions, or downtime in the Hands-Free system or those of its vendors. It is your responsibility to monitor your positions and ensure that your signals are being executed effectively at all times. Your use of Hands-Free confirms your acceptance of this waiver and confirms that you have read, comprehended, and agree to the Hands-Free `,
  
	`Terms of Service and Privacy Policy, which includes additional disclaimers and restrictions.`,
  ];

  export const styling = {
	backgroundColor: '#f0f0f0',
	padding: '20px',
	borderRadius: '5px',
	fontFamily: 'monospace',
	whiteSpace: 'pre-wrap',
	wordWrap: 'break-word',
	margin: '20px auto',
	boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
	color: 'black',
	width: '100%'
}
