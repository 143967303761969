import { useLocation, useNavigate } from 'react-router-dom';
import { RouterPathName } from '../routes/config';

// Custom hook to check location.state and handle redirection
const useCheckLocationState = (redirectPath = RouterPathName.notfound) => {
    const location = useLocation();
    const navigate = useNavigate();

    // Function to check if location.state is present
    const areValuesPresent = (locationState) => {
        return locationState !== null && locationState !== undefined;
    };

    // Get the current path from the location object
    const currentPath = location.pathname;

    // Check if the current path is 'managed-strategies'
    if ((currentPath === RouterPathName.managedStrategies || currentPath === `${RouterPathName.managedStrategies}/`)) {
        if (!areValuesPresent(location.state)) {
            // If location.state is not present, navigate to 'not-found'
            navigate(redirectPath);
            return false; // Indicate that the state is not valid
        }
    }
    return true; // Indicate that the state is valid
};

export default useCheckLocationState;
