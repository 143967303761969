import { createSlice } from "@reduxjs/toolkit";
import { postSetupAccount } from "../../reducer/setup-account/setupAccountReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const setupAccountSlice = createSlice({
  name: "setUp Account",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postSetupAccount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postSetupAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload
      })
      .addCase(postSetupAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default setupAccountSlice.reducer;
