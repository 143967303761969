import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../slice";

let store;
export const getStore = () => {
  if (!store) {
    store = makeStore();
  }
  return store;
};

export function makeStore(preloadedState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.REACT_APP_NODE_ENV !== "production"
  });
}

export default makeStore;
