import { createSlice } from "@reduxjs/toolkit";
import { postResendOtp } from "../../reducer/resend-otp/resendOtpReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const resendOtpSlice = createSlice({
  name: "resentOtp",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(postResendOtp.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postResendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.concat(action.payload);
      })
      .addCase(postResendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default resendOtpSlice.reducer;
