import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_BROKER_ACCOUNT_LIST } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const brokerAccountList = createAsyncThunk(
  ACTION_BROKER_ACCOUNT_LIST.broker_account_list,
  async (data) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL +
          APP_PATH.broker_account_list +
          `?account_type=${data}`
      );
      return response.data;
    } catch (error) {
      console.error("Error in Broker Account List:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);
