import { createSlice } from "@reduxjs/toolkit";
import {
  addPaperProduct,
  clearMarketListProducts,
  getMarketListProducts,
  postFavouriteMarketList,
  subscribeProductSession,
} from "../../reducer/market-list-strategies/marketlistReducer";



//default value
const initialState = {
  data: [],
  isLoading: false,
  current_page: 0,
  total_page: 0,
  next_page: null,
  count: 0,
  error: null,
  isSuccess: false,
};

const marketlistSlice = createSlice({
  name: "market-list",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(getMarketListProducts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMarketListProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        if (action.payload?.results) {
          const existingIds = new Set(state.data.map(item => item.id)); // Collect existing IDs
          const newData = action.payload.results.filter(item => !existingIds.has(item.id)); // Filter duplicates
          state.data = [...state.data, ...newData];
          state.next_page = action.payload.next;
        }
      })

      .addCase(getMarketListProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(clearMarketListProducts.fulfilled, (state, action) => {
        state.data = [];
        state.current_page = 0;
        state.next_page = null;
        state.count = 0;
        state.isSuccess = false;
        state.error = null;
      })

      //post like-unlike postFavouriteMarketList
      .addCase(postFavouriteMarketList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postFavouriteMarketList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.map((item) => {
          if (item.id === action?.payload?.product) {
            return {
              ...item,
              is_favourite: action?.payload.is_favourite,
            };
          } else {
            return item;
          }
        });
      })
      .addCase(postFavouriteMarketList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //for paper-demo-strategy
      .addCase(addPaperProduct.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addPaperProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.map((item) => {
          if (item.id === action?.payload?.response?.product_id) {
            return {
              ...item,
              is_subscribed: !item.is_subscribed,
            };
          } else {
            return item;
          }
        });
      })
      .addCase(addPaperProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // for subscribe live strategy
      .addCase(subscribeProductSession.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(subscribeProductSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.map((item) => {
          if (item.id === action?.payload?.response?.product_id) {
            return {
              ...item,
              is_subscribed: !item.is_subscribed,
            };
          } else {
            return item;
          }
        });
      })
      .addCase(subscribeProductSession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
});

export default marketlistSlice.reducer;