import { createAsyncThunk } from "@reduxjs/toolkit";
import { UPDATE_PROFILE } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const getUpdateProfile = createAsyncThunk(
  UPDATE_PROFILE.get_update_profile,
  async ({ access_token, refresh_token }) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + APP_PATH.get_update_profile,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "User-Refresh-Token": `${refresh_token}`,
        },
      }
    );
    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  UPDATE_PROFILE.update_profile,
  async ({ access_token, refresh_token, userProfileDetail }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}${APP_PATH.update_profile}`,
        userProfileDetail,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "User-Refresh-Token": `${refresh_token}`,
          },
        }
      );
      if (response.data.status === 200) {
        toast.success("Updated Successfully");
      } else if (response.data.status === 500) {
        if (response.data.message.first_name) {
          toast.error(response.data.message.first_name[0]);
        } else if (response.data.message.last_name) {
          toast.error(response.data.message.last_name[0]);
        } else if (response.data.message.dob) {
          toast.error(response.data.message.dob[0]);
        } else if (response.data.message.phone) {
          toast.error(response.data.message.phone[0]);
        } else if (response.data.message.non_field_errors) {
          toast.error(response.data.message.non_field_errors[0]);
        } else if (response.data.message.billing_email) {
          toast.error(response.data.message.billing_email[0]);
        }
      } else {
        toast.error("Something went wrong");
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching in update profile:", error);
      throw error;
    }
  }
);


