import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGOUT } from "../../action-types/actions";
import axios from "axios";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";

export const logout = createAsyncThunk(
	LOGOUT.logout,
	async ({ access_token, refresh }) => {
	  try {
		const response = await axios.post(
		  `${process.env.REACT_APP_API_URL}${APP_PATH.logout}`,
		  { refresh },
		  {
			headers: {
			  Authorization: `Bearer ${access_token}`,
			  'User-Refresh-Token': `${refresh}`,
			},
		  }
		);
		if (response.data.status === 200) {
		  toast.success(response.data.response);
		}
		return response.data;
	  } catch (error) {
		console.error('Logout failed:', error);
		toast.error('Logout failed. Please try again.');
		throw error.response?.data || new Error(error.message);
	  }
	}
  );
