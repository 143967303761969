import React from "react";
import styles from "../../styles/__dashboard-side-nav.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import mainMenuStyle from "../../styles/__dashboard-main-menu.module.scss";
import { sidebar } from "../../utils/Constants";
import useCheckLocationState from "../../hooks/useCheckLocationState";
import { RouterPathName } from "../../routes/config";

const DashboardSidebar = () => {
	const location = useLocation();
	const isStateValid = useCheckLocationState(RouterPathName.notfound);

	// If state is not valid, do not render the sidebar
	if (!isStateValid) {
		return null;
	}

	// Helper function to check if the link should be active
	const isLinkActive = (itemPath) => {
		// Broker link conditions
		if (itemPath === RouterPathName.currentBrokerAccount &&
			(location.pathname === RouterPathName.chooseBroker ||
				location.pathname === RouterPathName.reviewBrokerDisclaimer ||
				location.pathname === RouterPathName.provideCredential ||
				location.pathname === RouterPathName.chooseLivePaperAccount ||
				location.pathname === RouterPathName.ViewConnectedBrokerDetails
			)) {
			return true;
		}

		// Purchased strategy and managed strategies condition
		if (itemPath === RouterPathName.purchasedStrategy && location.pathname === RouterPathName.managedStrategies) {
			return false;
		}

		if (itemPath === RouterPathName.tradingPlans && location.pathname === RouterPathName.ViewTradingPlanSummary) {
			return true;
		}


		// Add more conditions if needed
		return false; // Return false if no conditions match
	};

	return (
		<React.Fragment>
			<nav id={`${mainMenuStyle['left-sidebar-open']}`}>
				<div className={`${styles['collapse']} ${styles['show']} ${styles['width']}`} id={`${styles['sidebar']}`}>
					<div className={`${styles['list-group']} border-0 card text-center text-md-left`}>
						{sidebar?.map((item) => (
							<NavLink
								key={item?.id}
								to={item?.path}
								className={({ isActive }) =>
									// Use the helper function to check active conditions
									isActive || isLinkActive(item?.path)
										? `${styles['list-group-item']} ${styles['active']}`
										: `${styles['list-group-item']}`
								}
							>
								<img src={item?.img} alt="" className={`${styles["side-icon"]}`} />
								<span className="hidden-sm-down">{item?.name}</span>
							</NavLink>
						))}
					</div>
				</div>
			</nav>
		</React.Fragment>
	);
};

export default DashboardSidebar;
