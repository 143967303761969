import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	ACTION_MANAGE_STRATEGIES,
} from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const createManageStrategies = createAsyncThunk(
	ACTION_MANAGE_STRATEGIES.create_managed_strategies,
	async (data) => {
		const axiosPrivate = await useAxiosPrivate();
		try {
			const response = await axiosPrivate.post(
				process.env.REACT_APP_API_URL + APP_PATH.create_managed_strategies,
				data
			);
			const statusCode = response?.data?.status;
			const message = response?.data?.message;
			if (statusCode === 200) {
				toast.success('Trade Plan Setting Saved Sucessfully');
			} else if (statusCode === 500) {
				if (message) {
					for (const key in message) {
						if (message.hasOwnProperty(key) && message[key]) {
							const formattedKey = key.replace(/_/g, ' ');
							toast.error(`${formattedKey} ${message[key]}`);
							break; // If you want to show only the first error, otherwise remove this line to show all errors
						}
					}
				}
			}
			return response.data;
		} catch (error) {
			console.error("Error in manageStrategies:", error);
			toast.error("Something went wrong");
			throw error;
		}
	}
);

export const getManageStrategies = createAsyncThunk(
	ACTION_MANAGE_STRATEGIES.get_managed_strategies,
	async (strategyId) => {
		const axiosPrivate = await useAxiosPrivate();
		try {
			const response = await axiosPrivate.get(
				process.env.REACT_APP_API_URL + APP_PATH.get_manage_strategies_by_id + strategyId + '/'
			);
			return response.data;
		} catch (error) {
			console.error("Error in getManageStrategies:", error);
			toast.error("Something went wrong");
			throw error;
		}
	}
);

export const updateManageStrategies = createAsyncThunk(
	ACTION_MANAGE_STRATEGIES.update_managed_strategies,
	async ({data, tradeId}) => {
		const axiosPrivate = await useAxiosPrivate();
		try {
			const response = await axiosPrivate.put(
				process.env.REACT_APP_API_URL + APP_PATH.update_managed_strategies + tradeId +'/',
				data
			);
			const statusCode = response?.data?.status;
			const message = response?.data?.message;
			if (statusCode === 200) {
				toast.success('Trade Plan Setting Updated Sucessfully');
				return response.data;
			} else if (statusCode === 500) {
				if (message) {
					for (const key in message) {
						if (message.hasOwnProperty(key) && message[key]) {
							// eslint-disable-next-line
							const formattedKey = key.replace(/_/g, ' ');
							toast.error(`${message[key]}`);
							break; // If you want to show only the first error, otherwise remove this line to show all errors
						}
					}
				}
			}
		} catch (error) {
			console.error("Error in Update Managed Strategy:", error);
			toast.error("Something went wrong");
		}
	}
);