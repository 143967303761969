import { combineSlices } from "@reduxjs/toolkit";
import subscriptionReducer from "../slice/subscription/subscriptionSlice";
import companyReducer from "../slice/company/companySlice";
import registerReducer from "../slice/register/registerSlice";
import timezoneReducer from "../slice/timezone/timezoneSlice";
import tradegurusReducer from "../slice/tradegurus/tradegurusSlice";
import loginReducer from "../slice/login/loginSlice";
import emailVerificationReducer from "../slice/verification/emailVerificationSlice";
import resendOtpReducer from "../slice/resend-otp/resendOtpSlice";
import emailValidReducer from "../slice/email-valid/emailValidSlice";
import setupAccountReducer from "../slice/setup-account/setupAccountSlice";
import googleUserInfoReducer from "../slice/user-info/userInfoSlice";
import contactUsReducer from "../slice/contact-us/contactSlice";
import googleLoginReducer from "../slice/google-login/googleLoginSlice";
import forgotPasswordReducer from "../slice/forgot-password/forgotPasswordSlice";
import resetPasswordReducer from "../slice/reset-password/resetPasswordSlice";
import marketlistReducer from "../slice/market-strategies-list/marketlistSlice";
import twoFactorAuthenticationReducer from "./two-factor-authentication/TwoFactorAuthenticationSlice";
import twoFactorVerifyReducer from "./two-factor-verify/twoFactorVerifySlice";
import brokerListReducer from "../slice/broker-list/brokerListSlice";
import connectBrokerReducer from "../slice/connect-to-broker/connectToBrokerSlice";
import getUpdateProfileReducer from "../slice/get-update-profile/getUpdateProfileSlice";
import brokerCallbackReducer from "../slice/broker-callback/brokerCallbackSlice"
import brokerTradierDataReducer from "../slice/broker-tradier-data/brokerTradierDataSlice";
import brokerConnectedReducer from "../slice/broker-connected/broderConnectedSlice"
import brokerDisconnectedReducer from "../slice/broker-disconnected/brokerDisconnectedSlice";
import brokerAccountListReducer from "../slice/broker_account_list/brokerAccountListSlice";
import brokerPaperAccountReducer from "../slice/broker_paper_account/brokerPaperAccountSlice";
import newsletterReducer from '../slice/news-letter/newsLetterSlice';
import userDetailsReducer from '../slice/user-details/userDetailsSlice';
import purchasedListReducer from '../slice/purchased-strategy-list/PurchasedListSlice'
import checkBrokerReducer from "../slice/check-broker/checkBrokerSlice";
import brokerAccountNumberReducer from "../slice/fetch-account-number/fetchAccountNumberSlice";
import strategyListReducer from "./strategy-list/strategyListSlice"; 
import manageStrategiesReducer from "./manage-strategies/manageStrategiesSlice";
import tradingPlanReducer from "../slice/trading-plan/tradingPlanSlice"
import pricingPlanReducer from "../slice/pricing-plan/pricingPlanSlice"


export default combineSlices({
    subscription: subscriptionReducer,
    company: companyReducer,
    register: registerReducer,
    timezone: timezoneReducer,
    tradegurus: tradegurusReducer,
    login: loginReducer,
    verification: emailVerificationReducer,
    resentOtp: resendOtpReducer,
    emailValid: emailValidReducer,
    setupAccount: setupAccountReducer,
    googleUserInfo: googleUserInfoReducer,
    contactUsInfo: contactUsReducer,
    googleLogin: googleLoginReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    twoFactorAuthentication: twoFactorAuthenticationReducer,
    twoFactorVerify: twoFactorVerifyReducer,
    brokerList: brokerListReducer,
    connectBroker: connectBrokerReducer,
    getUpdateProfile: getUpdateProfileReducer,
    marketListStrategies: marketlistReducer,
    brokerCallback: brokerCallbackReducer,
    brokerTradierData: brokerTradierDataReducer, 
    brokerConnected: brokerConnectedReducer,
    brokerDisconnected: brokerDisconnectedReducer,
    brokerAccountList: brokerAccountListReducer,
    brokerPaperAccount:brokerPaperAccountReducer,
    newsletter:newsletterReducer,
    userDetails: userDetailsReducer,
    purchasedList: purchasedListReducer,
    checkBroker: checkBrokerReducer,
    brokerAccountNumber:brokerAccountNumberReducer,
    strategyList:strategyListReducer,
    manageStrategies: manageStrategiesReducer,
    tradingPlan: tradingPlanReducer,
    pricingPlan: pricingPlanReducer
});
