import { createSlice } from "@reduxjs/toolkit";
import { createManageStrategies, getManageStrategies, updateManageStrategies } from "../../reducer/manage-strategies/manageStrategiesReducer";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const manageStrategiesSlice = createSlice({
  name: "manageStrategies",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
    //POST Strategies
      .addCase(createManageStrategies.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createManageStrategies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createManageStrategies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //GET Strategies
      .addCase(getManageStrategies.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getManageStrategies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data =  action.payload.response;
      })
      .addCase(getManageStrategies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      .addCase(updateManageStrategies.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateManageStrategies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateManageStrategies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
});

export default manageStrategiesSlice.reducer;
