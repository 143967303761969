import { createSlice } from "@reduxjs/toolkit";
import {
  brokerPaperAccount,
  clearBrokerPaperAccount,
} from "../../reducer/broker_paper_account/brokerPaperAccountReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const brokerPaperAccountSlice = createSlice({
  name: "brokerPaperAccount",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(brokerPaperAccount.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(brokerPaperAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(brokerPaperAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(clearBrokerPaperAccount.fulfilled, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.error = null;
      });
  },
});

export default brokerPaperAccountSlice.reducer;
