import { createSlice } from "@reduxjs/toolkit";
import {
  clearNewsLetter,
  postNewsLetter,
} from "../../reducer/news-letter/newsLetterReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const NewsLettersubscriptionSlice = createSlice({
  name: "news-subscriptions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(postNewsLetter.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postNewsLetter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.concat(action.payload);
      })
      .addCase(postNewsLetter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(clearNewsLetter.fulfilled, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.error = null;
      });
  },
});

export default NewsLettersubscriptionSlice.reducer;
