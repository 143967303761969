import { createAsyncThunk } from "@reduxjs/toolkit";
import { NEW_LETTER_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postNewsLetter = createAsyncThunk(
  NEW_LETTER_ACTIONS.fetch_news_letter,
  async (payload) => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + APP_PATH.news_letter,
      payload
    );
    if (response.data.status === 200) {
      toast.success("Email subscribed sucessfully");
    } else if (response.data.status === 500) {
      if (response.data.message.email[0]) {
        toast.error(response.data.message.email[0]);
      }
    } else {
      toast.error("Server error occurred");
    }
    return response.data.response;
  }
);

export const clearNewsLetter = createAsyncThunk(
  NEW_LETTER_ACTIONS.clear_news_letter,
  () => {
    return [];
  }
);
