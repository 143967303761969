import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONNECT_TO_BROKER_ACTION } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const connectToBroker = createAsyncThunk(
  CONNECT_TO_BROKER_ACTION.connect_to_broker,
  async () => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.post(
        process.env.REACT_APP_API_URL + APP_PATH.connect_to_broker
      );
      return response.data.response;
    } catch (error) {
      console.error("Error in connect to broker:", error);
      toast.error("Something went wrong1111");
    }
  }
);
