import { createSlice } from "@reduxjs/toolkit";
import { fetchTradegurus } from "../../reducer/tradegurus/tradegurusReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: "tradegurus",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(fetchTradegurus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTradegurus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchTradegurus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default subscriptionSlice.reducer;
