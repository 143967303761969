import { createAsyncThunk } from "@reduxjs/toolkit";
import { POST_FORGOT_PASSWORD } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postForgotPassword = createAsyncThunk(
  POST_FORGOT_PASSWORD.post_forgot_password,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.forgot_password,
        payload
      );
      if (response.data.status === 200) {
        toast.success(response.data.response);
      } else if (response.data.status === 500) {
        toast.error(response.data.message.email[0]);
      } else {
        toast.error("Error occurred during forgot password");
      }
      return response.data;
    } catch (error) {
      console.error("Error occurred during forgot password:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);
