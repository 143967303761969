export const RouterPathName = {
    home: "/",
    login: "/login",
    dashboard: "/dashboard",
    marketPlace: '/market-place',
    purchasedStrategy: "/purchased-strategy",
    tradingPlans: "/trading-plans",
    termsConditions: "/terms-conditions",
    dashboardTermsConditions: '/dashboard-terms-conditions',
    dashboardPrivacyPolicy: '/dashboard-privacy-policy',
    mailVerification: '/verification',
    broker: "/broker",
    pricing: "/pricing",
    registerAccount: "/register-account",
    setupAccount: "/setup-account",
    googleSetupAccount: "/google-setup-account",
    faq: '/faq',
    aboutUs: '/about-us',
    contactUs: '/contact-us',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    features: '/features',
    tradeGuru: '/trade-guru',
    traders: '/traders',
    accountSettings: '/account-settings',
    privacyPolicy: '/privacy-policy',
    chooseBroker: '/choose-broker',
    reviewBrokerDisclaimer: '/review-broker-disclaimers',
    chooseLivePaperAccount: '/choose-live-paper-account',
    currentBrokerAccount: "/current-broker-accounts",
    twoFactorOtp: '/two-factor-otp',
    provideCredential: '/provide-credentials',
    managedStrategies: '/managed-strategies',
    pricingPlans: '/pricing-plans',
    tradeHistory: '/trade-history',
    notfound: "*",
    pricingPlansPayment: '/pricing-plans-payment',
    setToken: '/broker-set-token',
    profile: '/profile-settings',
    changePassword: '/change-password',
    ViewConnectedBrokerDetails:"/view-connected-broker",
    ViewTradingPlanSummary: "/view-trading-plan-summary"
}