import { createSlice } from "@reduxjs/toolkit";
import {
  brokerCallback,
  clearBrokerCallback,
} from "../../reducer/broker-callback/brokerCallbackReducer";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const brokerCallbackSlice = createSlice({
  name: "brokercallback",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(brokerCallback.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(brokerCallback.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(brokerCallback.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(clearBrokerCallback.fulfilled, (state, action) => {
        state.data = {};
        state.isLoading = false;
        state.error = null;
      });
  },
});

export default brokerCallbackSlice.reducer;
