import { createAsyncThunk } from "@reduxjs/toolkit";
import { REGISTER_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postRegister = createAsyncThunk(
  REGISTER_ACTIONS.post_register,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.google_account_setup,
        payload
      );
      const statusCode = response.data.status;
      if (statusCode === 200) {
        toast.success("User registered successfully");
      }

      if (statusCode === 500) {
        if (response.data.message.non_field_errors) {
          toast.error(response.data.message.non_field_errors[0]);
        } else if (response.data.message.phone) {
          toast.error(response.data.message.phone[0]);
        } else if (response.data.message.last_name) {
          toast.error(response.data.message.last_name[0]);
        } else if (response.data.message.first_name) {
          toast.error(response.data.message.first_name[0]);
        } else {
          toast.error("An error got occured please try again..!");
        }
      }
      return response.data.response;
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error("Something went wrong");
    }
  }
);
