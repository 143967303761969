import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONTACT_US_INFO } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postContactValid = createAsyncThunk(
  CONTACT_US_INFO.post_contact_us_info,
  async (data) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.contact_us,
        data
      );
      if (response.data.status === 200) {
        toast.success(response.data.response);
      } else if (response.data.status === 500) {
        if (response.data.message.email[0]) {
          toast.error(response.data.message.email[0]);
        } else if (response.data.message.full_name[0]) {
          toast.error(response.data.message.full_name[0]);
        } else if (response.data.message.message[0]) {
          toast.error(response.data.message.message[0]);
        } else {
          toast.error("Server error occurred");
        }
      }
      return response.data;
    } catch (error) {
      toast.error("Something went wrong");
      throw error;
    }
  }
);
