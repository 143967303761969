import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_BROKER_LIST_ACTION } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const brokerList = createAsyncThunk(
  GET_BROKER_LIST_ACTION.get_broker_list,
  async (data) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      if ((typeof data === "boolean") === false) {
        const response = await axiosPrivate.get(
          process.env.REACT_APP_API_URL + APP_PATH.broker_list
        );
      
        return response?.data?.response;
      } else {
        const response = await axiosPrivate.get(
          process.env.REACT_APP_API_URL +
            APP_PATH.broker_list +
            `?isBrokerConnected=${data}`
        );
        return response?.data?.response;
      }
    } catch (error) {
      console.error("Error in broker list", error.message);
      toast.error("Something went wrong");
    }
  }
);

export const clearBrokerList = createAsyncThunk(
  GET_BROKER_LIST_ACTION.clear_broker_list,
  () => {
    return [];
  }
);
