import { createAsyncThunk } from "@reduxjs/toolkit";
import { GOOGLE_USER_INFO } from "../../action-types/actions";
import axios from "axios";

export const postGoogleUserInfo = createAsyncThunk(
  GOOGLE_USER_INFO.post_google_user_info,
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GOOGLE_USER_INFO_URL}?access_token=${payload.token}`
      );
      const responseData = {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
      };
      return responseData;
    } catch (error) {
      console.error("Error fetching Google user info:", error);
      throw error;
    }
  }
);
