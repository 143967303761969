import { createSlice } from "@reduxjs/toolkit";
import { fetchTimezone } from "../../reducer/timezone/timezoneReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: "timezone",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(fetchTimezone.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTimezone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.concat(action.payload);
      })
      .addCase(fetchTimezone.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default subscriptionSlice.reducer;
