import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  ACTION_PAYMENT,
  FAVOURITE_MARKET_LIST,
  PAPER_PAYMENT,
  PURCHASED_LIST_STRATEGIES,
} from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const getPurchasedListProducts = createAsyncThunk(
  PURCHASED_LIST_STRATEGIES.get_purchased_list,
  async ({start}) => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_API_URL}${APP_PATH.purchased_strategy_list}?page=${start}`
      );
      if (response.data.status === 200) {
        return response?.data?.response;
      }
    } catch (error) {
      console.error("Error in purchased list fetch:", error);
      toast.error("Something went wrong");
    }
  }
);

export const clearPurchasedListProducts = createAsyncThunk(
  PURCHASED_LIST_STRATEGIES.clear_product_list,
  () => {
    return [];
  }
);

export const postFavouritePurchasedList = createAsyncThunk(
  FAVOURITE_MARKET_LIST.post_favourite_market_list,
  async (payload) => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.post(
        process.env.REACT_APP_API_URL + APP_PATH.favourite_market_list,
        payload
      );
      if (response.data.status === 200) {
        response.data.response.is_favourite
          ? toast.success("Marked as Favourite")
          : toast.success("Marked as Unfavourite");
        return response.data.response;
      } else if (response.data.status === 500) {
        const { message } = response.data;
        if (message.non_field_errors) {
          toast.error(message.non_field_errors[0]);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      } else if (response.data.status === 401) {
        toast.error(response.data.message.detail);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      toast(error.data.message);
      console.log("Error", error.data.message);
    }
  }
);

//for unsubscribe paper
export const unsubscribePaperProduct = createAsyncThunk(
  PAPER_PAYMENT.unsubscribe_paper_payment,
  async (data) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.post(
        process.env.REACT_APP_API_URL + APP_PATH.unsubscribe_paper_strategy,
        data
      );
      if (response.data.status === 200) {
        toast.success(response?.data?.response?.msg);
      }
      return response.data;
    } catch (error) {
      console.error("Error in unsubscribe paper product:", error);
      toast.error("Something went wrong");
    }
  }
);

// for unsubscribe resubscribe live
export const unsubscribeLiveProduct = createAsyncThunk(
  ACTION_PAYMENT.unsubscribe_live_payment,
  async (payload) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.put(
        process.env.REACT_APP_API_URL +
          APP_PATH.unsubscribe_live_strategy +
          `${payload?.id}/`,
        { is_auto_renewed: payload.is_auto_renewed }
      );
      if (response.data.status === 200) {
        toast.success(response?.data?.response?.msg);
      }
      return response.data;
    } catch (error) {
      console.error("Error in unsubscribe Live Product:", error);
      toast.error("Something went wrong");
    }
  }
);