import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_CHECK_BROKER } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const checkBroker = createAsyncThunk(
  ACTION_CHECK_BROKER.broker_accounts_connected_check,
  async (data) => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL +
          APP_PATH.broker_accounts_connected_check +
          `${data === "paper" ? `?broker_account_type=${data}` : ""}`
      );
      if (response.data.status === 500) {
        return response.data.message;
      }
      return response.data.response;
    } catch (error) {
      console.error("Error in check broker:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);
