import './App.scss';
import RouterApp from './routes/router';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from 'react';
import { CookieConsentProvider } from './context/CookieConsentContext';
import CookieConsentBanner from './components/cookie-consent-banner/CookieConsentBanner';

const  App = ()=> {
    const [iscookies, setIsCookies] = useState(false)

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        setIsCookies(cookieConsent === null ? true : JSON.parse(cookieConsent));
      }, []);
  return (
    <CookieConsentProvider>
        <div className="App">
            <RouterApp />
            {iscookies && (
                <CookieConsentBanner iscookies={iscookies} setIsCookies={setIsCookies} />
            )}
        </div>
    </CookieConsentProvider>
);
}

export default App;

// Run APP.
