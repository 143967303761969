import { createAsyncThunk } from "@reduxjs/toolkit";
import { COMPANY_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const fetchCompany = createAsyncThunk(
  COMPANY_ACTIONS.fetch_company,
  async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + APP_PATH.company
      );
      return response.data.response;
    } catch (error) {
      console.error("Error in fetchCompany:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);
export const clearCompany = createAsyncThunk(
  COMPANY_ACTIONS.clear_company,
  () => {
    return [];
  }
);
