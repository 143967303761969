import { createAsyncThunk } from "@reduxjs/toolkit";
import { PRICING_PLAN_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

export const getPricingPlanList = createAsyncThunk(
  PRICING_PLAN_ACTIONS.pricing_plan_list,
  async () => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + APP_PATH.pricing_plan_list
      );
      const status = response.data.status;
      if (status === 500) {
        toast.error(response?.message?.detail);
      }
      return response.data.response;
    } catch (error) {
      console.error("Error in trading plan user list:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);

export const createPlanSubscription = createAsyncThunk(
  PRICING_PLAN_ACTIONS.pricing_plan_subscription,
  async (data) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.post(
        process.env.REACT_APP_API_URL + APP_PATH.pricing_plan_subscription,
        data
      );
      const status = response?.data?.status;
      if (status === 200) {
        sessionStorage.setItem(
          "Session_ID",
          response?.data?.response?.["session_id"]
        );
        window.location.href = response?.data?.response?.["session_url"];
      } else if (status === 500) {
        if (response?.data?.message?.is_agree) {
          toast.error(response?.data?.message?.is_agree[0]);
        } else if (response?.data?.message?.is_auto_renewed) {
          toast.error(response?.data?.message?.is_auto_renewed[0]);
        } else if (
          response?.data?.message?.is_agree &&
          response?.data?.message?.is_auto_renewed
        ) {
          toast.error(response?.data?.message?.is_agree[0]);
        } else {
          toast.error(response?.data?.message);
        }
      }
      return response.data;
    } catch (error) {
      console.error("Error in create plan subscription:", error);
      toast.error("Something went wrong");
    }
  }
);

export const subscriptionPaymentSuccess = createAsyncThunk(
  PRICING_PLAN_ACTIONS.success_plan_subscription,
  async () => {
    const data = sessionStorage.getItem("Session_ID");
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL +
          APP_PATH.subscription_payment_success +
          `?session_id=${data}`
      );
      if (response) {
        sessionStorage.removeItem("Session_ID");
      }
      return response?.data?.response;
    } catch (error) {
      console.error("Error", error.message);
      toast.error("Something went wrong");
    }
  }
);

export const updateSubscriptionPlan = createAsyncThunk(
  PRICING_PLAN_ACTIONS.update_subscription_plan,
  async (payload) => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.post(
        process.env.REACT_APP_API_URL + APP_PATH.update_subscription_plan,
        payload
      );
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
      } else if (response?.data?.status === 500) {
        toast.error(response?.data?.message?.is_auto_renewed[0]);
      }
      return response.data;
    } catch (error) {
      toast(error.data.message);
      console.log("Error in update subscription plan", error.data.message);
    }
  }
);
