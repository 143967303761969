export const Images = {
    tradierLogo: require('../assets/images/seal-1.png'),  //used in ManagedStrategies, TradingPlans
    tradeStationLogo: require('../assets/images/doc-img-1.png'), // used in broker-edit component
    Innerbanner: require('../assets/images/inner-banner.png'), // used in all public components except forms
    Coin1: require('../assets/images/coin-1.png'), // used in all public components except forms
    Coin3: require('../assets/images/coin-3.png'), // used in all public components except forms
    Chart: require('../assets/images/chart.png'), // used in about us component
    Img1: require('../assets/images/img1.png'), // used in about us component
    Icon8: require('../assets/images/icon-8.png'), // used in about us component
    Icon9: require('../assets/images/icon-9.png'), // used in about us component
    Icon10: require('../assets/images/icon-10.png'), // used in about us component
    Icon1: require('../assets/images/new1.png'), // used in broker component
    Icon2: require('../assets/images/new2.png'), // used in broker component
    Icon3: require('../assets/images/new3.png'), // used in broker component
    Icon4: require('../assets/images/new4.png'), // used in broker component
    BrokerImg: require('../assets/images/broker-img-1.png'), // used in broker & Home component
    GroupImg: require('../assets/images/Group.png'), // used in broker component
    DocImg1: require('../assets/images/doc-img-1.png'), // used in broker-edit component
    FAQBG: require('../assets/images/faq-bg.png'), // used in FAQ and ContactUs component
    downArrowQuesImg: require('../assets/images/downArrowQuestion.png'), //used in contact us
    ArrowUp: require('../assets/images/arrow-up.png'), //used in Dashboard component
    GraphG: require('../assets/images/graph-g.png'), //used in Dashboard component
    GraphR: require('../assets/images/graph-r.png'), //used in Dashboard component
    GraphLG: require('../assets/images/graph-lg.png'), //used in Dashboard component
    CalIcon: require('../assets/images/cal-icon.png'), //used in Dashboard component
    BrkImg1: require('../assets/images/brk-sum1.png'), //used in Dashboard component
    BrkImg2: require('../assets/images/brk-sum2.png'), //used in Dashboard component
    BrkImg3: require('../assets/images/brk-sum3.png'), //used in Dashboard component
    BrkImg4: require('../assets/images/brk-sum4.png'), //used in Dashboard component
    BrkImg5: require('../assets/images/brk-sum5.png'), //used in Dashboard component
    BrkImg6: require('../assets/images/brk-sum6.png'), //used in Dashboard component
    NoTrade: require('../assets/images/no-trade.png'), //used in Dashboard component
    ArrowRight: require('../assets/images/arrow-right.png'), //used in Dashboard component
    PlayVideo: require('../assets/images/play-vd.png'), //used in Dashboard component
    VideoBG: require('../assets/images/video-bg1.jpg'), //used in Dashboard component
    BoxI1: require('../assets/images/box-i1.png'), //used in Dashboard component
    BoxI2: require('../assets/images/box-i2.png'), //used in Dashboard component
    BoxI3: require('../assets/images/box-i3.png'), //used in Dashboard component
    BoxI4: require('../assets/images/box-i4.png'), //used in Dashboard component
    Deploy_Strategy: require('../assets/images/DeployStrategy.png'), //used in Features component
    Icon11: require('../assets/images/icon-11.png'), //used in Features component
    Icon12: require('../assets/images/icon-12.png'), //used in Features component
    Icon13: require('../assets/images/icon-13.png'), //used in Features component
    Icon14: require('../assets/images/icon-14.png'), //used in Features component
    Market_Place: require('../assets/images/Market-Place.png'), //used in Features component
    Market_Strategy: require('../assets/images/MarketStrategy.png'), //used in Features component
    LogoShort: require('../assets/images/logo-short.png'), // used in all the components where form is submitted
    Banner: require('../assets/images/banner-img.jpg'), // used in home component
    MethodImg: require('../assets/images/methode-img.jpg'), // used in home component
    Coin2: require('../assets/images/coin-2.png'), // used in home component
    ServiceCoin: require('../assets/images/service-icon1.png'), // used in home component
    ServiceCoin2: require('../assets/images/service-icon2.png'), // used in home component
    ServiceCoin3: require('../assets/images/service-icon3.png'), // used in home component
    ServiceCoin4: require('../assets/images/service-icon4.png'), // used in home component
    ServiceCoin5: require('../assets/images/service-icon5.png'), // used in home component
    ServiceCoin6: require('../assets/images/service-icon6.png'), // used in home component
    SupportedBrokerImg: require('../assets/images/supported-brokers.png'), // used in home component
    BroLogo1: require('../assets/images/bro-logo1.png'), // used in home component
    BroLogo2: require('../assets/images/bro-logo2.png'), // used in home component
    BroLogo3: require('../assets/images/bro-logo3.png'), // used in home component
    FaqImg: require('../assets/images/faq-img.png'), // used in home component
    TestImg1: require('../assets/images/test-img1.png'), // used in home component
    TestImg2: require('../assets/images/test-img2.png'), // used in home component
    TestQuote: require('../assets/images/test-quote.png'), // used in home component
    ArtImg1: require('../assets/images/Article1.png'), // used in home component
    ArtImg2: require('../assets/images/Article2.png'), // used in home component
    ArtImg3: require('../assets/images/Article3.png'), // used in home component
    googleImg: require('../assets/images/google.png'), // used in login and register account component
    Heart1: require('../assets/images/wish-1.png'), // used in MarketPlace and Purchasedstrategy component
    Heart2: require('../assets/images/Heart.png'), // used in MarketPlace and Purchasedstrategy component
    ErrorImg: require('../assets/images/error-404.png'), // used in Not-found component
    PriceImage: require('../assets/images/price-bg-shap.png'), // used in Pricing component
    planIcon: require('../assets/images/prc-dollar.png'), // used in Pricing component
    Guru1: require('../assets/images/guru-1.png'), // used in Trade-guru component
    Guru2: require('../assets/images/guru-2.png'), // used in Trade-guru component
    Guru3: require('../assets/images/guru-3.png'), // used in Trade-guru component
    Guru4: require('../assets/images/guru-4.png'), // used in Trade-guru component
    Guru5: require('../assets/images/guru-5.png'), // used in Trade-guru component
    Trade_Guru: require('../assets/images/tradeguru.png'), // used in Trade-guru component
    WhyPartner: require('../assets/images/WhyPartner.png'), // used in Trade-guru component
    BrokerImage: require('../assets/images/trading.png'), // used in Trade-History component
    BellImg: require('../assets/images/trade-set2.png'), // used in Trade-History component
    Adobe: require('../assets/images/Adobe.png'), // used in Traders component
    GroupImage: require('../assets/images/Group-img.png'), // used in Traders component
    Marketplace: require('../assets/images/Marketplace.png'), // used in Traders component
    SmartMoney: require('../assets/images/SmartMoney.png'), // used in Traders component
    view: require('../assets/images/view-i.png'), // used in Trading-Plan component
    Head: require('../assets/images/head-i1.png'), // used in Trading-Plan component
    remove: require('../assets/images/remove-i.png'), // used in Trading-Plan component
    SubsImg: require('../assets/images/subs-img.png'), // used in Email-verification common component
    Logo: require('../assets/images/logo.png'), // used in Footer2 common component
    Cookies: require('../assets/images/cookie.png'), // used in CookiesConsentBanner common component
    DBlogo: require('../assets/images/dashboard-images/db-logo.png'), // used in Dashboard navbar
    newImg: require('../assets/images/nw.png'), // used in Dashboard navbar
    Chat1: require('../assets/images/dashboard-images/chat-1.png'), // used in Dashboard navbar
    Chat2: require('../assets/images/dashboard-images/chat-2.png'), // used in Dashboard navbar
    Chat3: require('../assets/images/dashboard-images/chat-3.png'), // used in Dashboard navbar
    Chat4: require('../assets/images/dashboard-images/chat-4.png'), // used in Dashboard navbar
    Chat5: require('../assets/images/dashboard-images/chat-5.png'), // used in Dashboard navbar
    UserProfile: require('../assets/images/dashboard-images/user-profile.png'), // used in Dashboard navbar
    Fl_icon: require('../assets/images/fl-icon.png'), // used in Filter Bars
    Gpay: require('../assets/images/g-pay.png'), // used in GetStaretd Modal
    Group1: require('../assets/images/Group1.png'), // used in GetStaretd Modal
    Group2: require('../assets/images/Group2.png'), // used in GetStaretd Modal
    trade1: require('../assets/images/trade-i1.png'), // used in Managed Strategies Component
    trade2: require("../assets/images/trade-i2.png"),// used in Managed Strategies Component
    trade3: require("../assets/images/trade-i3.png"),// used in Managed Strategies Component
    trade4: require("../assets/images/trade-i4.png"),// used in Managed Strategies Component
    trade5: require("../assets/images/trade-i5.png"),// used in Managed Strategies Component
    trade6: require("../assets/images/trade-i6.png"),// used in Managed Strategies Component
    tradeSet1: require("../assets/images/trade-set1.png"),// used in Managed Strategies Component
    tradeSet2: require("../assets/images/trade-set2.png"),// used in Managed Strategies Component
    tradeSet3: require("../assets/images/trade-set3.png"),// used in Managed Strategies Component
    tradeSet4: require("../assets/images/trade-set4.png"),// used in Managed Strategies Component
    tradeSet5: require("../assets/images/trade-set5.png"),// used in Managed Strategies Component
    tradeSet6: require("../assets/images/trade-set6.png"),// used in Managed Strategies Component
    tradeSet7: require("../assets/images/trade-set7.png"),// used in Managed Strategies Component
    tradeSet8: require("../assets/images/trade-set8.png"),// used in Managed Strategies Component
    tradeSet10: require("../assets/images/trade-set10.png"),// used in Managed Strategies Component
    tradeSet11: require("../assets/images/trade-set11.png"),// used in Managed Strategies Component
    setting: require("../assets/images/setting-i.png"),// used in Managed Strategies Component
    upArrow: require("../assets/images/downArrow.png"),// used in Managed Strategies Component
    downArrow: require("../assets/images/Up-arrow.svg").default,// used in Managed Strategies Component
    d1Time: require("../assets/images/dl-time.png"),// used in Managed Strategies Component
    option1: require("../assets/images/Option1.png"),// used in Managed Strategies Component
    roundImage: require("../assets/images/Round.png"), // used in Stepper Component
    user: require("../assets/images/user.svg").default, 
}