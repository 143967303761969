import { createSlice } from "@reduxjs/toolkit";
import {
  brokerList,
  clearBrokerList,
} from "../../reducer/broker-list/brokerListReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const brokerListSlice = createSlice({
  name: "Broker List",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(brokerList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(brokerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(brokerList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(clearBrokerList.fulfilled, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.error = null;
      });
  },
});

export default brokerListSlice.reducer;
