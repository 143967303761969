import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
	ACTION_PAYMENT,
	FAVOURITE_MARKET_LIST,
	MARKET_LIST_STRATERGY,
	PAPER_PAYMENT,
} from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const getMarketListProducts = createAsyncThunk(
	MARKET_LIST_STRATERGY.get_market_list,
	async ({ start }) => {
		try {
			const axiosPrivate = await useAxiosPrivate();
			const response = await axiosPrivate.get(
				`${process.env.REACT_APP_API_URL}${APP_PATH.market_list}?page=${start}`
			);
			if (response.data.status === 200) {
				// return response?.data?.response;
				const { results, next, previous, count } = response.data.response;
				return { results, next, previous, count };
			} else if (response.data.status === 401) {
				toast.error(response.data.message.detail);
			} else if (response.data.status === 500) {
				toast.error(response.data.message.detail);
			} else {
				toast.error("An error has occured");
			}
		} catch (error) {
			console.error("Error in market list fetch:", error);
			toast.error("Something went wrong");
		}
	}
);


export const clearMarketListProducts = createAsyncThunk(
	MARKET_LIST_STRATERGY.clear_market_list,
	() => {
		return [];
	}
);

export const postFavouriteMarketList = createAsyncThunk(
	FAVOURITE_MARKET_LIST.post_favourite_market_list,
	async (payload) => {
		try {
			const axiosPrivate = await useAxiosPrivate();
			const response = await axiosPrivate.post(
				process.env.REACT_APP_API_URL + APP_PATH.favourite_market_list,
				payload
			);
			if (response.data.status === 200) {
				response.data.response.is_favourite
					? toast.success("Marked as Favourite")
					: toast.success("Marked as Unfavourite");

				return response.data.response;
			} else if (response.data.status === 500) {
				const { message } = response.data;
				if (message.non_field_errors) {
					toast.error(message.non_field_errors[0]);
				} else {
					toast.error("An error occurred. Please try again later.");
				}
			} else if (response.data.status === 401) {
				toast.error(response.data.message.detail);
			} else {
				toast.error("An error occurred. Please try again later.");
			}
		} catch (error) {
			toast(error.data.message);
			console.log("Error", error.data.message);
		}
	}
);
//for paper-demo
export const addPaperProduct = createAsyncThunk(
	PAPER_PAYMENT.subscribe_create_strategy_session,
	async (data) => {
		const axiosPrivate = await useAxiosPrivate();
		try {
			const response = await axiosPrivate.post(
				process.env.REACT_APP_API_URL + APP_PATH.paper_strategy,
				data
			);
			if (response.data.status === 200) {
				toast.success(response.data.response.msg);
			}
			return response.data;
		} catch (error) {
			console.error("Error in paper Product:", error);
			toast.error("Something went wrong");
		}
	}
);

// strategyPayment succsess get method
export const onPaymentSuccess = createAsyncThunk(
	ACTION_PAYMENT.strategy_payment_success,
	async () => {
		const data = sessionStorage.getItem("Session_ID");
		const axiosPrivate = await useAxiosPrivate();
		try {
			const response = await axiosPrivate.get(
				process.env.REACT_APP_API_URL +
				APP_PATH.strategy_payment_success +
				`?session_id=${data}`
			);
			if (response) {
				sessionStorage.removeItem("Session_ID");
			}
			// return response?.data?.response?.results;
		} catch (error) {
			console.error("Error", error.message);
			toast.error("Something went wrong");
		}
	}
);

// strategy payment success post API
export const subscribeProductSession = createAsyncThunk(
	ACTION_PAYMENT.subscribe_create_strategy_session,
	async (data) => {
		const axiosPrivate = await useAxiosPrivate();
		try {
			const response = await axiosPrivate.post(
				process.env.REACT_APP_API_URL + APP_PATH.create_strategy,
				data
			);
			const status = response.data.status;
			if (status === 200) {
				const sessionId = response?.data?.response?.["session_id"];
				const sessionUrl = response?.data?.response?.["session_url"];
				sessionStorage.setItem(
					"Session_ID",
					sessionId
				);
				window.location.href = sessionUrl;
			} else if (status === 500) {
				toast.error(response.data.message)

			} else {
				toast.error("An Error Occured");
			}
			return response.data;
		} catch (error) {
			console.error("Error in fetchCompany:", error);
			toast.error("Something went wrong");
		}
	}
);