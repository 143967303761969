import { createSlice } from "@reduxjs/toolkit";
import { postLogin } from "../../reducer/login/loginReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(postLogin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default loginSlice.reducer;
