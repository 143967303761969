import { createAsyncThunk } from "@reduxjs/toolkit";
import { SETUP_ACCOUNT_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postSetupAccount = createAsyncThunk(
  SETUP_ACCOUNT_ACTIONS.post_setup_account,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.account_signup,
        payload
      );
      const statusCode = response.data.status;

      if (statusCode === 200) {
        toast.success("Account set done successfully");
      }

      if (statusCode === 500) {
        if (
          response.data.message.user_other_detail &&
          response.data.message.user_other_detail.phone
        ) {
          toast.error(response.data.message.user_other_detail.phone[0]);
        } else if (response.data.message.non_field_errors) {
          toast.error(response.data.message.non_field_errors[0]);
        } else if (
          response.data.message.user_other_detail &&
          response.data.message.user_other_detail.first_name
        ) {
          toast.error(response.data.message.user_other_detail.first_name[0]);
        } else if (
          response.data.message.user_other_detail &&
          response.data.message.user_other_detail.last_name
        ) {
          toast.error(response.data.message.user_other_detail.last_name[0]);
        } else {
          toast.error("An unknown error occured please try again");
        }
      }
      return response.data;
    } catch (error) {
      console.log("Error:", error);
      return toast.error("Something went wrong..!");
    }
  }
);
