import { createAsyncThunk } from "@reduxjs/toolkit";
import { EMAIL_VERIFICATION_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postEmailVerification = createAsyncThunk(
  EMAIL_VERIFICATION_ACTIONS.post_verification,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.email_verification,
        payload
      );

      if (response.data.status === 200) {
        return response.data;
      } else if (response.data.status === 500) {
        toast.error(response.data.message.otp[0]);
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  }
);
