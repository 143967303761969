import { createSlice } from "@reduxjs/toolkit";
import { brokerTradierData } from "../../reducer/broker-tradier-data/brokerTradierDataReducer";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const brokerTradierDataSlice = createSlice({
  name: "brokerTradierData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(brokerTradierData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(brokerTradierData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(brokerTradierData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default brokerTradierDataSlice.reducer;
