import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { ACTION_BROKER_PAPER_ACCOUNT } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";

export const brokerPaperAccount = createAsyncThunk(
  ACTION_BROKER_PAPER_ACCOUNT.broker_paper_account,
  async (data) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.post(
        process.env.REACT_APP_API_URL + APP_PATH.broker_paper_account,
        data
      );

      const status = response.data.status;
      if (status === 200) {
        return response.data;
      } else if (status === 500 && response.data.message.access_token) {
        toast.error(response.data.message.access_token[0]);
      } else if (status === 500 && response.data.message) {
        toast.error(response.data.message);
      }

    } catch (error) {
      console.error("Error in broker Paper Account:", error);
      toast.error("Something went wrong1");
      throw error;
    }
  }
);

export const clearBrokerPaperAccount = createAsyncThunk(
  ACTION_BROKER_PAPER_ACCOUNT.clear_broker_paper_account,
  () => {
    return {};
  }
);
