import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getStore } from './store/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={getStore()}>
    <GoogleOAuthProvider clientId="276971385155-5tmmbu3mi5afdo47nfmo6h7kk2s5mpco.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>
);

reportWebVitals();


