import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_BROKER_CONNECTED } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const brokerConnected = createAsyncThunk(
  ACTION_BROKER_CONNECTED.broker_connected,
  async () => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + APP_PATH.broker_connected
      );
      return response.data;
    } catch (error) {
      console.error("Error in Broker connection:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);
