import { createSlice } from "@reduxjs/toolkit";
import { brokerAccountList } from "../../reducer/broker_account_list/brokerAccountListReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const brokerAccountListSlice = createSlice({
  name: "brokerAccountList",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(brokerAccountList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(brokerAccountList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(brokerAccountList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    //   .addCase(clearBrokerList.fulfilled, (state, action) => {
    //     state.data = [];
    //     state.isLoading = false;
    //     state.error = null;
    //   });
  },
});

export default brokerAccountListSlice.reducer;
