import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_BROKER_TRADIER_DATA } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const brokerTradierData = createAsyncThunk(
	ACTION_BROKER_TRADIER_DATA.broker_tradier_data,
	async (data) => {
		const axiosPrivate = await useAxiosPrivate();
		try {
			const response = await axiosPrivate.post(process.env.REACT_APP_API_URL + APP_PATH.broker_tradier_data, data);
			const status = response.data.status
			if (status === 200) {
				toast.success(response.data.response);
			} else if (status === 500) {
				if(response.data.message.max_strategy_positions) {
					toast.error(response.data.message.max_strategy_positions[0])
				}
			} else {
				toast.error('An Error Occured')
			}

			return response.data;
		} catch (error) {
			console.error('Error in broker Tradier data:', error);
			toast.error("Something went wrong")
		}
	}
);

