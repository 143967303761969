import { createSlice } from "@reduxjs/toolkit";
import { postGoogleLogin } from "../../reducer/google-login/googleLoginReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const googleLoginSlice = createSlice({
  name: "google-login",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postGoogleLogin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postGoogleLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = [action.payload.data];
      })
      .addCase(postGoogleLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default googleLoginSlice.reducer;
