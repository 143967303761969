import { Navigate, Outlet } from "react-router-dom";
import { RouterPathName } from "./config";
import DashboardNavbar from "../components/dashboard-navbar/DashboardNavbar";
import React, { useEffect, useState } from "react";
import CookieConsentBanner from "../components/cookie-consent-banner/CookieConsentBanner";
import DashboardSidebar from "../components/dashboard-sidebar/DashboardSidebar";

const ProtectedRoute = () => {
  const token = sessionStorage.getItem("usr_1a2b3c");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  if (!isMounted) return null;

  return token ?
    <React.Fragment>
      <DashboardNavbar />
      <DashboardSidebar />
      <Outlet />
      <CookieConsentBanner />
    </React.Fragment> :
    <Navigate to={RouterPathName.login} replace />;
};

export default ProtectedRoute;

