import React, { useEffect, useState } from "react";
import styles from "../../styles/__signalstrength.module.scss";

const SignalStrength = ({ userDetails }) => {
    const [signal, setSignal] = useState();

    useEffect(() => {
        if (userDetails?.subscription_plan_name === "Free") {
            let signals = Math.floor(userDetails?.signals / 2);
            setSignal(signals);
        } else if (userDetails?.subscription_plan_name === "Essential") {
            let signals = Math.floor(userDetails?.signals / 10);
            setSignal(signals);
        } else if (userDetails?.subscription_plan_name === "Plus") {
            let signals = Math.floor(userDetails?.signals / 50);
            setSignal(signals);
        } else if (userDetails?.subscription_plan_name === "Premium") {
            let signals = Math.floor(userDetails?.signals / 200);
            setSignal(signals);
        } else {
            setSignal(0);
        }
    }, [userDetails]);

    const getSignalColor = (index) => {
        if (index < signal) {
          return signal === 5 ? '#20c920e6' : signal === 1 ? 'red' : '#efae04';
        }
        return 'gray';
      };

    return (
        <div className={`${styles["signal-container"]}`}>
            {[...Array(5)].map((_, index) => (
                <div
                    key={index}
                    className={`${styles["bar"]}`}
                    style={{backgroundColor: `${getSignalColor(index)}`, height: `${(index + 1) * 5}px`, width: `${2}px` }}
                ></div>
            ))}
        </div>
    );
}

export default SignalStrength;
