import { createAsyncThunk } from "@reduxjs/toolkit";
import { POST_RESET_PASSWORD } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postResetPassword = createAsyncThunk(
  POST_RESET_PASSWORD.post_reset_password,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.reset_password,
        payload
      );
      if (response.data.status === 200) {
        toast.success(response.data.response);
      } else if (response.data.status === 500) {
        if (response.data.message.non_field_errors) {
          toast.error(response.data.message.non_field_errors[0]);
        } else if (response.data.message.email) {
          toast.error(response.data.message.email[0]);
        } else if (response.data.message.otp) {
          toast.error(response.data.message.otp[0]);
        }
      }
      return response.data;
    } catch (error) {
      console.error("Error occurred during password reset:", error);
      toast.error("Something went wrong");
      throw error; // Re-throw the error to let Redux Toolkit know that the operation failed
    }
  }
);
