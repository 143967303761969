import { createSlice } from "@reduxjs/toolkit";
import { postEmailValid } from "../../reducer/email-valid/emailValidReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const emailValidSlice = createSlice({
  name: "emailValid",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postEmailValid.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postEmailValid.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(postEmailValid.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default emailValidSlice.reducer;
