import { createAsyncThunk } from "@reduxjs/toolkit";
import { TWO_FACTOR_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const twoFactorEnabled = createAsyncThunk(
  TWO_FACTOR_ACTIONS.two_factor_enabled,
  async ({ access_token, refresh_token }) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + APP_PATH.two_factor_enabled,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "User-Refresh-Token": `${refresh_token}`,
          },
        }
      );
      if (response.data.status === 200) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      toast.error("An error occurred while fetching two-factor status.");
      throw error;
    }
  }
);

export const twoFactorDisabled = createAsyncThunk(
  TWO_FACTOR_ACTIONS.two_factor_disabled,
  async ({ refresh_token, access_token, disableOtp }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${APP_PATH.two_factor_disabled}${disableOtp}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "User-Refresh-Token": `${refresh_token}`,
          },
        }
      );
      if (response.data.status === 200) {
        toast.success(response.data.response);
      } else if (response.data.status === 500) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        toast.error(
          `Error: ${error.response.data.message || "An error occurred"}`
        );
      } else if (error.request) {
        toast.error("Error: No response from server. Please try again later.");
      } else {
        toast.error(`Error: ${error.message}`);
      }
      return {};
    }
  }
);

export const twoFactorLogin = createAsyncThunk(
  TWO_FACTOR_ACTIONS.two_factor_login,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.two_factor_login,
        payload
      );
      if (response.data.status === 200) {
        toast.success("Otp successfully register");
      } else if (response.data.status === 500) {
        if (response.data.message.non_field_errors) {
          toast.error(response.data.message.non_field_errors[0]);
        }else if (response.data.message.email) {
          toast.error(response.data.message.email[0]);
        } else if (response.data.message.token)
          toast.error(response.data.message.token[0]);
      } else {
        toast.error("An error occured");
      }
      return response.data;
    } catch (error) {
      console.log("Error:", error);
    }
  }
);
