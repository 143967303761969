import { createSlice } from "@reduxjs/toolkit";
import { brokerDisconnected } from "../../reducer/broker_disconnected/brokerDisconnectedReducer";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const brokerDisconnectedSlice = createSlice({
  name: "brokerDisconnected",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(brokerDisconnected.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(brokerDisconnected.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(brokerDisconnected.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default brokerDisconnectedSlice.reducer;
