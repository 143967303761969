import { createSlice } from "@reduxjs/toolkit";
import { postGoogleUserInfo } from "../../reducer/user-info/userInfoReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const userInfoSlice = createSlice({
  name: "user-info",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(postGoogleUserInfo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postGoogleUserInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        const payload = {
          email: action.payload.data.email,
          email_verified: action.payload.data.email_verified,
          picture: action.payload.data.picture,
          status: action.payload.status,
        };
        state.data = payload;
      })
      .addCase(postGoogleUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default userInfoSlice.reducer;
