import { createSlice } from "@reduxjs/toolkit";
import { checkBroker } from "../../reducer/check_broker/checkBrokerReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const checkBrokerSlice = createSlice({
  name: "check-broker",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(checkBroker.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkBroker.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(checkBroker.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default checkBrokerSlice.reducer;
