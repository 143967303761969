export const SUBSCRIPTION_ACTIONS = {
  fetch_subscription: "subscription/fetchSubscription",
  clear_subscription: "subscription/clearSubscription",
};

export const COMPANY_ACTIONS = {
  fetch_company: "comapany/fetchComapany",
  clear_company: "company/clearCompany",
};

export const NEW_LETTER_ACTIONS = {
  fetch_news_letter: "news_letter/fetchNewsLetter",
  clear_news_letter: "news_letter/clearNewsLetter",
};

export const REGISTER_ACTIONS = {
  post_register: "user/postRegister",
};

export const LOGIN_ACTIONS = {
  post_login: "user/postLogin",
};

export const TIMEZONE_ACTIONS = {
  fetch_timezone: "user/timezone",
};

export const TRADEGURUS_ACTIONS = {
  fetch_tradegurus: "tradegurus/tradegurusList",
};

export const EMAIL_VERIFICATION_ACTIONS = {
  post_verification: "verification/emailVerification",
};

export const RESEND_OTP_ACTIONS = {
  post_resend_otp: "resendOtp/postResendOtp",
};

export const EMAIL_VALID_ACTIONS = {
  post_email_valid: "emailValid/postEmailValid",
};

export const SETUP_ACCOUNT_ACTIONS = {
  post_setup_account: "setupAccount/postSetupAccount",
};

export const GOOGLE_USER_INFO = {
  post_google_user_info: "googleUserInfo/postGoogleUserInfo",
};

export const POST_GOOGLE_LOGIN = {
  post_google_login: "googleLogin/postGoogleLogin",
};
export const CONTACT_US_INFO = {
  post_contact_us_info: "contactus/postContactValid",
};

export const POST_FORGOT_PASSWORD = {
  post_forgot_password: "forgotPassword/postForgotPassword",
};

export const POST_RESET_PASSWORD = {
  post_reset_password: "resetPassword/postResetPassword",
};

export const MARKET_LIST_STRATERGY = {
  get_market_list: "market-list/getMarketListStrategies",
  clear_market_list:"market-list/clearMarketListStrategies"
};

export const TWO_FACTOR_ACTIONS = {
  two_factor_enabled: "twoFactorEnabled/getTwoFactorEnabled",
  two_factor_disabled: "twoFactorDisabled/postTwoFactorDisabled",
  two_factor_verify: "twoFactorVerify/postTwoFactorVerify",
  two_factor_login: "twoFactorLogin/postTwoFactorLogin",
  generate_backup_codes: "generateBackupCodes/postGenerateBackupCodes",
  get_unused_bachup_codes: "unUsedBackupCode/getUnusedBackupCode"
};

export const FAVOURITE_MARKET_LIST = {
  post_favourite_market_list: "favourite_market-list/postFavouriteMarketList",
};

export const GET_BROKER_LIST_ACTION = {
  get_broker_list: "liveBroker/getBrokerList",
  clear_broker_list: "liveBroker/clearBrokerList",
};

export const CONNECT_TO_BROKER_ACTION = {
  connect_to_broker: "Connect/connectBroker",
};

export const UPDATE_PROFILE = {
  update_profile: "updateProfile/putUpdateProfile",
  get_update_profile: "updateProfile/getUpdateProfile",
};

export const LOGOUT = {
  logout: "logout/postLogout",
};

export const ACTION_BROKER_CALLBACK = {
  broker_callback: "brokerCallback/callback",
  clear_broker_callBack: "brokerCallback/clearCallback",
};

export const ACTION_BROKER_TRADIER_DATA = {
  broker_tradier_data: "brokerTradier/tradierData",
};

export const ACTION_BROKER_CONNECTED = {
  broker_connected: "broker/brokerConnected",
};

export const ACTION_BROKER_DISCONNECT = {
  broker_disconnected: "broker/brokerDisonnected",
};

export const ACTION_BROKER_ACCOUNT_LIST = {
  broker_account_list: "broker/brokerAccountList",
};

export const ACTION_BROKER_PAPER_ACCOUNT = {
  broker_paper_account: "broker/brokerPaperAccount",
  clear_broker_paper_account: "broker/clearBrokerPaperAccount",
};

export const USER_DETAILS = {
  user_details: "users/fetchUserDetails",
};

export const PURCHASED_LIST_STRATEGIES = {
  get_purchased_list: "purchased-list/getPurchasedListStrategies",
  clear_product_list: "purchased-list/clearPurchasedListStrategies"
};

export const ACTION_PAYMENT = {
  subscribe_create_strategy_session: "subscribe/createStrategySession",
  strategy_payment_success: "strategy/paymentSuccess",
  unsubscribe_live_payment: "unsubscribe/livePayment",
};

export const PAPER_PAYMENT = {
  subscribe_paper_broker: "subscribe/paperBroker",
  unsubscribe_paper_payment: "unsubscribe/paper",
};
export const ACTION_CHECK_BROKER = {
  broker_accounts_connected_check: "check/brokerAccountsConnected",
};
export const ACTION_FETCH_ACCOUNT_NUMBER = {
  fetch_account_number: "fetch/accountNumber",
};

export const ACTION_TRADING_INSTRUCTION_LIST = {
  strategy_list: "trading/instructionList",
};

export const ACTION_MANAGE_STRATEGIES = {
  create_managed_strategies: "insert/manageStrategies",
  get_managed_strategies: "get/manageStrategies",
  update_managed_strategies: "edit/manageStrategies"
};

export const TRADING_PLAN_ACTIONS = {
  trading_plan_user_list: "get/tradingPlanList",
  delete_trading_plan:"delete/tradingPlan",
  trading_plan_status:"put/tradingPlanStatus"
};

export const PRICING_PLAN_ACTIONS = {
  pricing_plan_list: "get/pricingPlanList",
  pricing_plan_subscription: "post/createSubscription",
  success_plan_subscription: "get/successPlanSubscription",
  update_subscription_plan: "post/updateSubscriptionPlan",
};
