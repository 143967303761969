import { createSlice } from "@reduxjs/toolkit";
import { generateBackupCodes, getUnusedBackupCode, twoFactorVerify } from "../../reducer/two-factor-verify/twoFactorVerifyReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const twoFactorVerifySlice = createSlice({
  name: "two-factor-verify",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // two factor verify
      .addCase(twoFactorVerify.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(twoFactorVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(twoFactorVerify.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // post generate backup codes
      .addCase(generateBackupCodes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(generateBackupCodes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data= action.payload
      })
      .addCase(generateBackupCodes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

        // get unused backup code
        .addCase(getUnusedBackupCode.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(getUnusedBackupCode.fulfilled, (state, action) => {
          state.isLoading = false;
        })
        .addCase(getUnusedBackupCode.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })
  },
});

export default twoFactorVerifySlice.reducer;
