import { createAsyncThunk } from "@reduxjs/toolkit";
import { TRADING_PLAN_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

export const getTradingPlanUserList = createAsyncThunk(
  TRADING_PLAN_ACTIONS.trading_plan_user_list,
  async () => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + APP_PATH.trading_plan_user_list
      );
      return response?.data?.response;
    } catch (error) {
      console.error("Error in trading plan user list:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);


export const deleteTradingPlan = createAsyncThunk(
  TRADING_PLAN_ACTIONS.delete_trading_plan,
  async (tradingPlanID) => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.delete(
        process.env.REACT_APP_API_URL + APP_PATH.delete_trading_plan + tradingPlanID + '/'
      );
      const statusCode = response?.data?.status;
      if (statusCode === 200) {
        toast.success('Trading Plan Successfully Deleted')
        return response?.data?.response;
      } else if (statusCode === 500) {
        toast.error(`We're sorry, something went wrong on our end. Please try again later. If the problem persists, feel free to contact our support team.`)
      }
    } catch (error) {
      console.error("Error while deleting trading plan user list:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);

export const tradingPlanStatus = createAsyncThunk(
  TRADING_PLAN_ACTIONS.trading_plan_status,
  async (payload) => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.put(
        process.env.REACT_APP_API_URL + APP_PATH.trading_plan_status + `${payload?.id}/`,payload
      );
      if (response?.data?.status === 200) {
      
        return response.data?.response;
      } 
      
      if (response?.data?.status === 500) {
        toast.error(response.data?.message[0]);
      }
    } catch (error) {
      console.error("Error in trading plan status:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);