import $ from "jquery";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

export const handleScroll = () => {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 1) {
            $(".header-section").addClass("sticky");
        } else {
            $(".header-section").removeClass("sticky");
        }
    });
}

export const setupMenuBehavior = () => {
    $(".mobile_btn").on("click", function () {
        $(".main_menu").slideToggle();
        $(".mobile_btn i").toggleClass("fa-xmark fa-xl");
    });

    if ($(window).width() < 992) {
        $(".main_menu  ul li a").on("click", function () {
            $(this)
                .parent(".has_dropdown")
                .children(".sub_menu")
                .stop()
                .slideToggle();

            $(this)
                .parent(".has_dropdown")
                .children("a")
                .find(".fa-angle-right")
                .stop()
                .toggleClass("fa-rotate-90");
        });
    }
};

export const setupAccordion = () => {
    let question = document.querySelectorAll(".cus-accordion-btn button");
    question.forEach((question) => {
        question.addEventListener("click", (event) => {
            const active = document.querySelector(".cus-accordion-btn button.active");
            if (active && active !== question) {
                active.classList.toggle("active");
                active.nextElementSibling.style.maxHeight = 0;
            }
            question.classList.toggle("active");
            const answer = question.nextElementSibling;
            if (question.classList.contains("active")) {
                answer.style.maxHeight = answer.scrollHeight + "px";
            } else {
                answer.style.maxHeight = 0;
            }
        });
    });
};

export const scrollTop = () => {
    var counted = 0;
    $(window).scroll(function () {
        var oTop = $("#counter").offset()?.top - window.innerHeight;
        if (counted === 0 && $(window).scrollTop() > oTop) {
            $(".counting").each(function () {
                var $this = $(this),
                    countTo = $this.attr("data-count");
                $({
                    countNum: $this.text(),
                }).animate(
                    {
                        countNum: countTo,
                    },

                    {
                        duration: 2000,
                        easing: "swing",
                        step: function () {
                            $this.text(Math.floor(this.countNum));
                        },
                        complete: function () {
                            $this.text(this.countNum);
                            //alert('finished');
                        },
                    }
                );
            });
            counted = 1;
        }
    });
}

// Utility function to omit specified fields from an object
export const omitFields = (obj, fieldsToOmit) => {
    return Object.keys(obj).reduce((result, key) => {
        if (!fieldsToOmit.includes(key)) {
            result[key] = obj[key];
        }
        return result;
    }, {});
};

export const toggleTradingPlanAccordion = () => {
    $(document).ready(function () {
        $(".at-title").click(function () {
            $(this)
                .toggleClass("active")
                .next(".table-tab")
                .slideToggle()
                .parent()
                .siblings()
                .find(".table-tab")
                .slideUp()
                .prev()
                .removeClass("active");
        });
    });
}

export const storeUserInfo = (Authentication) => {
    const fieldsToOmit = ['access_token', 'refresh_token', 'is_two_factor_enabled', 'first_name', 'last_name', 'full_name', 'is_two_factor_enabled',
        'is_email_verified', 'signals', 'trade_gurus', 'user_role'];
    const userInfo = omitFields(Authentication.response, fieldsToOmit);
    sessionStorage.setItem('wxyz_5uv', JSON.stringify(userInfo));
}

export const storeTokensInSession = (Authentication) => {
    const accessToken = Authentication.response.access_token;
    const refreshToken = Authentication.response.refresh_token;
    sessionStorage.setItem("usr_1a2b3c", accessToken);
    sessionStorage.setItem("usr_4d5e6f", refreshToken);
}

export const capitalizeFirstWord = (field) => {
    let modifiedField = field.replace(/_/g, ' ');
    modifiedField = modifiedField.toLowerCase();
    modifiedField = modifiedField.charAt(0).toUpperCase() + modifiedField.slice(1);
    return modifiedField;
}

export const convertStatus = (status) => {
    if (status === 'entry_only') {
        return 'Entry Only';
    } else if (status === 'entry_and_exit') {
        return 'Entry & Exit';
    } else {
        return '';
    }
};

export const openModal = () => {
    document.body.classList.add("modal-open");
};

export const closeModal = () => {
    document.body.classList.remove("modal-open");
};

// Helper function to convert time to UTC format
export const convertToUTC = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    // Format as HH:mm:ss
    const utcHours = String(date.getUTCHours()).padStart(2, '0');
    const utcMinutes = String(date.getUTCMinutes()).padStart(2, '0');
    const utcSeconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${utcHours}:${utcMinutes}:${utcSeconds}`;
};

export const toNumber = (value) => {
    // Check if the value is already a number
    if (typeof value === 'number') {
        return value;
    }

    // Try to convert the value to a number
    const num = Number(value);

    // Return NaN if the conversion result is not a finite number
    if (isNaN(num) || !isFinite(num)) {
        return NaN;
    }

    return num;
}

export const getPositionSizeName = (formData) => {
    const { position_size_type, fixed_quantity, amount_per_position, risk_per_position, percent_of_equity } = formData;

    switch (position_size_type) {
        case 'fixed_quantity':
            return `Quantity of ${fixed_quantity}`;
        case 'amount_per_position':
            return `$${amount_per_position} Worth`;
        case 'risk_per_position':
            return `$${risk_per_position} Risk`;
        case 'percent_of_equity':
            return `${percent_of_equity}% of Equity`;
        default:
            return `Quantity of 1`;
    }
};

export const getTakeProfitName = (formData) => {
    const { take_profit_amount, take_profit_percentage, daily_take_profit } = formData;

    if (!take_profit_amount && !take_profit_percentage && !daily_take_profit) {
        return 'None';
    }

    if (take_profit_percentage) {
        return `${take_profit_percentage}% Take Profit`;
    }

    if (take_profit_amount) {
        return `$${take_profit_amount} Take Profit`;
    }

    if (daily_take_profit) {
        return `Enabled`;
    }

    return 'None'; // Fallback in case no conditions are met
};


export const getStopLossName = (formData) => {
    const { stop_loss_amt, stop_loss_percentage } = formData;

    if (stop_loss_amt) {
        return `$${stop_loss_amt}`;
    }

    if (stop_loss_percentage && parseInt(stop_loss_percentage, 10) !== 0) {
        return `${stop_loss_percentage}% Stop`;
    }

    return 'None'; // Default value when no conditions are met
};


export const getNotificationsName = (formData) => {
    const { failure_email_notify, email_notify } = formData;

    if (failure_email_notify && email_notify) {
        return 'Only Failed Signals';
    }

    if (!failure_email_notify && email_notify) {
        return 'Everything';
    }

    return 'No Notifications'; // Default value when no conditions are met
};


export const getRetryName = (formData) => {
    const { retry_trade, max_retry } = formData;

    if (retry_trade) {
        return `Enabled - ${max_retry ? max_retry : 5} Retries`;
    }

    return 'Disabled'; // Default value when retry_trade is not enabled
};


export const getExitSettingsName = (formData) => {
    const { exit_order_type } = formData;

    return exit_order_type === 'stop_market' ? 'Stop Order' : 'Stop Limit Order';
};

// utils.js or in the same file
export const getEntrySettingsName = (formData) => {
    const { entry_order_type } = formData;

    return entry_order_type === 'limit' ? 'Limit Order' : 'Market Order';
};

export const convertToMilliseconds = (value) => {
    // Check if the value is a number and is equal to or greater than 1000.00
    if (!isNaN(value) && value >= 1000.00) {
        // Convert to milliseconds by multiplying by 1000
        return value * 1000;
    } else {
        console.error('Value should be a number and greater than or equal to 1000.00');
        return null;
    }
}
