import { createSlice } from "@reduxjs/toolkit";
import { fetchUserDetails } from "../../reducer/user-details/userDetailsReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const userDetailsSlice = createSlice({
  name: "user Details",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(fetchUserDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload[0];
        state.status = "succeeded";
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default userDetailsSlice.reducer;
