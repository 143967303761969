import { createSlice } from "@reduxjs/toolkit";
import {
  clearSubscription,
  fetchSubscriptions,
} from "../../reducer/subscription/subscriptionReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(fetchSubscriptions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = state.data.concat(action.payload);
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(clearSubscription.fulfilled, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.error = null;
      });

    // Post Operation

    //Delete Operation

    // Update Operation
  },
});

export default subscriptionSlice.reducer;
