import { createSlice } from "@reduxjs/toolkit";
import {
  getUpdateProfile,
  updateProfile,
} from "../../reducer/get-update-profile/getUpdateProfileReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const getUpdateProfileSlice = createSlice({
  name: "getUpdateProfile",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(getUpdateProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUpdateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getUpdateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //put Operation
      .addCase(updateProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default getUpdateProfileSlice.reducer;
