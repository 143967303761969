import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducer/logout/logoutReducer";
import { RouterPathName } from "../../routes/config";
import { getUpdateProfile } from "../../reducer/get-update-profile/getUpdateProfileReducer";
import { fetchUserDetails } from "../../reducer/user-details/userDetailsReducer";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../styles/__dashboard-nav.module.scss";
import mainMenuStyle from "../../styles/__dashboard-main-menu.module.scss";
import { Images } from "../../utils/ConstantImages";
import { navNotification, setup } from "../../utils/Constants";
import { BellIcon, ChatIcon, HamburgerIcon } from "../../utils/SVGIcons";
import SignalStrength from "../signal-strength/SignalStrength";

const DashboardNavbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const access_token = sessionStorage.getItem("usr_1a2b3c");
	const refresh_token = sessionStorage.getItem("usr_4d5e6f");
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [userDetails, setUserDetails] = useState();
	const [isComponentMounted, setIsComponentMounted] = useState(false);
	const currentWizard = useSelector(
		(state) => state?.getUpdateProfile?.data?.response?.hide_setup_wizard
	);
	const userFirstName = useSelector(
		(state) => state?.getUpdateProfile?.data?.response?.first_name
	);
	const userLastName = useSelector(
		(state) => state?.getUpdateProfile?.data?.response?.last_name
	);

	const toggleMenu = (e) => {
		e.preventDefault();
		setIsMenuOpen(!isMenuOpen);
		document.body.classList.toggle(mainMenuStyle["hide-sidebar"]);
	};

	const logoutHandler = () => {
		const access_token = sessionStorage.getItem("usr_1a2b3c");
		const refresh = sessionStorage.getItem("usr_4d5e6f");
		dispatch(logout({ access_token, refresh }))
			.then((response) => {
				const status = response?.payload?.status;
				if (status === 200) {
					sessionStorage.removeItem("usr_1a2b3c");
					sessionStorage.removeItem("usr_4d5e6f");
					sessionStorage.clear();
					navigate("/login");
				}
			})
			.catch((error) => {
				console.log("error in logout: ", error);
			});
	};

	useEffect(() => {
		let isMounted = true;
		dispatch(fetchUserDetails())
			.then((response) => {
				if (isMounted) {
					setUserDetails(response?.payload[0]);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		return () => {
			isMounted = false; // Cleanup function to set the flag to false
		};
	}, [dispatch]);

	useEffect(() => {
		let isMounted = true; // Track if the component is still mounted
		dispatch(getUpdateProfile({ access_token, refresh_token })).then(
			(response) => {
				if (isMounted) {
					setIsLoading(false);
					setIsComponentMounted(true);
				}
			}
		);
		return () => {
			isMounted = false; // Cleanup function to set the flag to false
		};
	}, [dispatch, access_token, refresh_token]);

	if (isLoading || !isComponentMounted) {
		return null; // Render nothing while loading or if the component is not mounted
	}

	const onPlanClickHandler = () => {
		navigate(RouterPathName?.pricingPlans);
	};

	return (
		<React.Fragment>
			<nav
				className={`${styles["main-top-nav"]} navbar navbar-expand-lg fixed-top`}
			>
				<div className={`${styles["navbar-logo-wrap"]}`}>
					<Link className="brand" to={RouterPathName.dashboard}>
						<img src={Images?.DBlogo} alt="db-logo" />
						<p className={`${styles["db-logo"]}`}>BETA</p>
					</Link>
					<Link id={`${styles["sidebar-collapse"]}`} onClick={toggleMenu}>
						<HamburgerIcon />
					</Link>
				</div>

				<div className={`${styles["ns-right"]} ${styles["right-topnav-wrap"]}`}>
					<div className={`${styles["s-right-header"]} dropdown`}>
						<Link href="#" className={`${styles["opensearch"]}`}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18.018"
								height="18.018"
								viewBox="0 0 18.018 18.018"
							>
								<path
									id="My_home"
									data-name="My home"
									d="M8.859.738a.361.361,0,0,1,0,.6l-.773.773a.361.361,0,0,1-.6,0L3.234-2.145a.405.405,0,0,1-.141-.281v-.492A7.1,7.1,0,0,1-1.687-1.125,7.044,7.044,0,0,1-6.855-3.27,7.044,7.044,0,0,1-9-8.437a7.044,7.044,0,0,1,2.145-5.168A7.044,7.044,0,0,1-1.687-15.75,7.044,7.044,0,0,1,3.48-13.605,7.044,7.044,0,0,1,5.625-8.437,7.1,7.1,0,0,1,3.832-3.656h.492a.334.334,0,0,1,.281.141Zm-14.52-5.2A5.417,5.417,0,0,0-1.687-2.812,5.417,5.417,0,0,0,2.285-4.465,5.417,5.417,0,0,0,3.938-8.437,5.417,5.417,0,0,0,2.285-12.41a5.417,5.417,0,0,0-3.973-1.652A5.417,5.417,0,0,0-5.66-12.41,5.417,5.417,0,0,0-7.312-8.437,5.417,5.417,0,0,0-5.66-4.465Z"
									transform="translate(9 15.75)"
									fill="#333"
								/>
							</svg>
						</Link>
						<div className={`${styles["tw-block"]}  d-flex align-items-center`}>
							<span
								title={
									userDetails?.subscription_plan_name
										? userDetails?.subscription_plan_name
										: "Free"
								}
								onClick={onPlanClickHandler}
								className={`${styles["bl-box"]}`}
							>
								{userDetails?.subscription_plan_name
									? userDetails?.subscription_plan_name
									: "Free"}
							</span>
							<span
								title={userDetails?.signals ? userDetails?.signals : "0"}
								className={`${styles["nw-box"]}  d-flex align-items-center`}
							>
								{/* <img src={Images?.newImg} alt="new-img" /> */}
								<SignalStrength userDetails={userDetails}/>{" "}
								<span>{userDetails?.signals ? userDetails?.signals : "0"}</span>
							</span>
						</div>

						<div
							data-bs-toggle="dropdown"
							className={`${styles["setup-block"]}  ${!currentWizard && !isLoading
								? "d-block dropdown"
								: "d-none dropdown"
								}`}
						>
							Setup
							<span className={`${styles["bl-box"]}`}>2/6</span>
							<div
								className={`${styles["setup-dropdown"]} ${styles["wizard-menu"]}  ${styles["dropdown-menus"]} dropdown-menu`}
							>
								<ul>
									{setup?.map((item) => (
										<li key={item?.id}>
											{" "}
											<img src={item?.img} alt="" className={`${styles["left_icon"]}`} />
											<Link href="#">
												<h2>
													{item?.heading}
													<i
														className={`${styles["angle-right"]} fa fa-angle-right`}
													></i>
												</h2>
												<p>{item?.content}</p>
											</Link>
										</li>
									))}
								</ul>
							</div>
						</div>

						<div
							className={`${styles["main-header-message"]} ${styles["navigation-item"]} dropdown nav-item`}
						>
							<Link
								className={`${styles["navigation-link"]} new nav-link`}
								href="#"
								data-bs-toggle="dropdown"
							>
								<ChatIcon />
								<span className={`${styles["pulse-danger"]}`}></span>
							</Link>
							<div className={`${styles["dropdown-menus"]} dropdown-menu`}>
								<div className={`${styles["menu-header-content"]} text-left`}>
									<div className="d-flex">
										<h6 className="dropdown-title mb-1 tx-15 text-white font-weight-semibold">
											Messages
										</h6>
										<Link
											href="#"
											className="ms-auto my-auto float-right fs-12"
										>
											Mark All Read
										</Link>
									</div>
									<p className="dropdown-title-text subtext mb-0 text-white op-6 pb-0 tx-12 ">
										You have 4 unread messages
									</p>
								</div>
								<div
									className={`${styles["main-message-list"]} ${styles["chat-scroll"]}`}
								>
									{navNotification?.map((item) => (
										<Link
											href="#"
											className={`${styles["border-bottom"]} p-3 d-flex`}
											key={item.id}
										>
											<div className={`${styles["drop-img"]}`}>
												<img src={item.img} alt="chat" srcSet="" />
												<span></span>
											</div>
											<div className="wd-90p">
												<div className="d-flex">
													<h5 className="mb-1">{item.name}</h5>
												</div>
												<p className="mb-0">{item.message}</p>
												<p
													className={`${styles["time"]} mb-0 text-left float-left mt-2`}
												>
													{item.Time}
												</p>
											</div>
										</Link>
									))}
								</div>
								<div
									className={`${styles["drop-menu-footer"]} dropdown-footer text-center `}
								>
									<Link to="">VIEW ALL</Link>
								</div>
							</div>
						</div>

						<div
							className={`${styles["navigation-item"]} ${styles["main-header-notification"]} dropdown nav-item`}
						>
							<Link className="nav-link" to={RouterPathName.tradeHistory}>
								<BellIcon />
								<span className={`${styles["pulse-success"]}`}></span>
							</Link>
						</div>

						<div className={`${styles["logged-in-user"]} dropdown`}>
							<Link
								className={`${styles["dropdown-toggler"]}  dropdown-toggle`}
								data-bs-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<img src={Images?.UserProfile} alt="user-profile" />
								<span
									className={`${styles["admin-details"]}`}
									title={
										userFirstName && userLastName
											? userFirstName + " " + userLastName
											: "Unknown"
									}
								>
									{" "}
									<span className={`${styles["admin-name"]}`}>
										{userFirstName && userLastName
											? userFirstName + " " + userLastName
											: "Unknown"}
									</span>
									<small>{userDetails?.user_role_name}</small>
								</span>
							</Link>
							<ul
								className={`${styles["dropdown-menus"]} ${styles["avtar-menu"]} dropdown-menu`}
								aria-labelledby="my-dropdown"
							>
								<li>
									<Link
										className={`${styles["account-list"]} dropdown-item`}
										to={RouterPathName.profile}
									>
										Profile
									</Link>
								</li>
								<li>
									<Link
										className={`${styles["account-list"]} dropdown-item`}
										href="#"
									>
										Message
									</Link>
								</li>
								<li>
									<Link
										className={`${styles["account-list"]} dropdown-item`}
										to={RouterPathName.accountSettings}
									>
										Account settings
									</Link>
								</li>
								<li>
									<button
										type="button"
										className={`${styles["account-list"]} dropdown-item`}
										onClick={logoutHandler}
									>
										Logout
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</React.Fragment>
	);
};
export default DashboardNavbar;
