import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_FETCH_ACCOUNT_NUMBER } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const fetchAccountNumber = createAsyncThunk(
  ACTION_FETCH_ACCOUNT_NUMBER.fetch_account_number,
  async ({ broker_id, broker_account_type }) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const API_URL =
        process.env.REACT_APP_API_URL +
        APP_PATH.fetching_account_number +
        `?broker_id=${broker_id ? broker_id : 0}${
          broker_account_type === "paper"
            ? `&broker_account_type=${broker_account_type}`
            : ""
        }`;
      const response = await axiosPrivate.get(API_URL);
      const statusCode = response.data.status;

      if (statusCode === 200) {
        return response.data;
      } else if (statusCode === 400) {
        toast.error("Error While Fetching Broker Account Number");
      } else {
        toast.error("Something went wrong...");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);
