import { createAsyncThunk } from "@reduxjs/toolkit";
import { EMAIL_VALID_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postEmailValid = createAsyncThunk(
  EMAIL_VALID_ACTIONS.post_email_valid,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.email_valid,
        payload
      );
      if (response.data.status_code === 200) {
        sessionStorage.setItem(
          "emailValidationData",
          JSON.stringify(response.data.data)
        );
      }
      return response.data;
    } catch (error) {
      if (error.response.data.status_code === 400) {
        toast.error(error.response.data.errors.email[0]);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      throw error;
    }
  }
);
