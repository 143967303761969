import axios from "axios";
import { APP_PATH } from "../api/backendPath";

const useRefreshToken = () => {
  const access_token = sessionStorage.getItem("usr_1a2b3c");
  const refresh_token = sessionStorage.getItem("usr_4d5e6f");

  const refresh = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.refresh,
        { refresh: refresh_token },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "User-Refresh-Token": `${refresh_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response?.data?.response.access;
    } catch (error) {}
  };
  return refresh;
};

export default useRefreshToken;
