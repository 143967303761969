import { createAsyncThunk } from "@reduxjs/toolkit";
import { TRADEGURUS_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";

export const fetchTradegurus = createAsyncThunk(
  TRADEGURUS_ACTIONS.fetch_tradegurus,
  async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + APP_PATH.tradeguru_list
      );
      return response?.data?.response;
    } catch (error) {
      console.log("Error:", error);
    }
  }
);
