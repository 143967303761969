import { createAsyncThunk } from "@reduxjs/toolkit";
import { TIMEZONE_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";

export const fetchTimezone = createAsyncThunk(TIMEZONE_ACTIONS.fetch_timezone, async () => {
    const response = await axios.get(process.env.REACT_APP_API_URL + APP_PATH.timezone)
    return response.data.data
});

