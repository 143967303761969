export const APP_PATH = {
  subscription: "/api/v1/users/fetch-subscription-detail/",
  company: "/api/v1/users/fetch-company-info/",
  news_letter: "/api/v1/users/newsletter-create/",
  login: "/api/token/",
  tradeguru_list: "/api/v1/users/fetch-tradeguru/",
  email_verification: "/api/v1/users/verify-email/",
  resend_opt: "/api/v1/users/send-email-otp/",
  email_valid: "/api/v1/users/signup/",
  account_signup: "/api/v1/users/account-setup/",
  google_login: "/api/v1/users/google-login/",
  forgot_password: "/api/v1/users/forgot-password/",
  reset_password: "/api/v1/users/reset-password/",
  contact_us: "/api/v1/users/contact-us/",
  google_account_setup: "/api/v1/users/google-account-setup/",
  two_factor_enabled: "/api/v1/users/totp/create/",
  two_factor_login: "/api/v1/users/totp/two-factor-login/",
  two_factor_verify: "/api/v1/users/totp/two-factor-verify/",
  two_factor_disabled: "/api/v1/users/totp/two-factor-disabled/",
  refresh: "/api/token/refresh/",
  broker_list: "/api/v1/broker/broker-list/",
  connect_to_broker: "/api/v1/broker/tradier/connect/",
  get_update_profile: "/api/v1/users/profile/",
  update_profile: "/api/v1/users/profile/",
  market_list: "/api/v1/broker/market-place-product-list/",
  favourite_market_list: "/api/v1/broker/product-liked/",
  logout: "/api/v1/users/logout/",
  broker_callback: "/api/v1/broker/tradier/auth/callback/",
  broker_tradier_data: "/api/v1/broker/tradier/account-update/",
  broker_connected: "/api/v1/broker/broker-accounts-connected-list/",
  broker_account_list: "/api/v1/broker/tradier/accounts-list/",
  account_disconnect: "/api/v1/broker/tradier/account-disconnect/",
  broker_paper_account: "/api/v1/broker/tradier/create-update-access-token/",
  user_details: "/api/v1/users/user-details/",
  purchased_strategy_list: "/api/v1/broker/product-purchased/",
  create_strategy: "/api/v1/payments/create-product-purchase-session/",
  strategy_payment_success: "/api/v1/payments/product-payment-success/",
  paper_strategy: "/api/v1/broker/demo-product-purchased/",
  unsubscribe_paper_strategy: "/api/v1/broker/demo-product-purchased/",
  unsubscribe_live_strategy: "/api/v1/broker/manage-live-product-subscription/",
  broker_accounts_connected_check:"/api/v1/broker/broker-accounts-connected-check/",
  strategy_list: "/api/v1/broker/strategy-list/",
  fetching_account_number: "/api/v1/broker/broker-accounts-connected-list/",
  create_managed_strategies: "/api/v1/broker/create-managed-trade-plan-settings/",
  update_managed_strategies: "/api/v1/broker/update-managed-trade-plan-settings/",
  get_manage_strategies_by_id: "/api/v1/broker/get-managed-trade-plan-settings/",
  trading_plan_user_list: "/api/v1/broker/list-all-managed-trade-plan-settings/",
  delete_trading_plan: "/api/v1/broker/delete-managed-trade-plan-settings/",
  pricing_plan_list: "/api/v1/users/subscription-plans/",
  pricing_plan_subscription: "/api/v1/payments/create-subscription-session/",
  subscription_payment_success:"/api/v1/payments/subscription-payment-success/",
  update_subscription_plan: "/api/v1/payments/update-subscription-plan/",
  generate_backup_codes: "/api/v1/users/generate-backup-codes/",
  unused_backup_codes: "/api/v1/users/get-unused-backup-code/",
  trading_plan_status: "/api/v1/broker/enable-disable-manage-trade-plan/"
};
