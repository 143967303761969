import { createSlice } from "@reduxjs/toolkit";
import {
	clearPurchasedListProducts,
	getPurchasedListProducts,
	postFavouritePurchasedList,
	unsubscribeLiveProduct,
	unsubscribePaperProduct,
} from "../../reducer/purchased-strategy-list/purchasedListReducer";

//default value
const initialState = {
	data: [],
	isLoading: false,
	error: null,
	next_page: null,
};

const PurchasedProductListSlice = createSlice({
	name: "purchased-strategies-list",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			// Get Operation
			.addCase(getPurchasedListProducts.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(getPurchasedListProducts.fulfilled, (state, action) => {
				state.isLoading = false;
				if (action.payload?.results) {
			
					// Avoid duplicacy by filtering unique items based on a key (e.g., 'id')
					const existingIds = new Set(state.data.map(item => item.id)); // Replace 'id' with the unique identifier of your data
					const newResults = action.payload.results.filter(item => !existingIds.has(item.id));
			
					state.data = [...state.data, ...newResults]; // Add only new unique items
					state.count = action.payload.count;
					state.next_page = action.payload.next;
				} else {
					state.error = "No data available.";
				}
			})
			// .addCase(getPurchasedListProducts.fulfilled, (state, action) => {
			// 	console.log('action: ', action);
			// 	state.isLoading = false;
			// 	if (action.payload?.results) {
			// 		console.log('action.payload?.results: ', action.payload?.results);
					
			// 		state.data = [...state.data, ...action.payload.results];
			// 		state.count = action.payload.count;
			// 		state.next_page = action.payload.next;
			// 	} else {
			// 		state.error = "No data available.";
			// 	}
			// })
			.addCase(getPurchasedListProducts.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})
			.addCase(clearPurchasedListProducts.fulfilled, (state, action) => {
				state.data = [];
				state.isLoading = false;
				state.error = null;
			})

			//post like-unlike postFavouritePurchasedList
			.addCase(postFavouritePurchasedList.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(postFavouritePurchasedList.fulfilled, (state, action) => {
				state.isLoading = false;
				if (Array.isArray(state.data)) {
					state.data = state.data.map((item) => {
						if (item.product.id === action.payload.product) {
							return {
								...item,
								product: {
									...item.product,
									is_favourite: action.payload.is_favourite,
								},
							};
						} else {
							return item;
						}
					});
				}
			})
			.addCase(postFavouritePurchasedList.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})

			//for unsubscribe paper demo
			.addCase(unsubscribePaperProduct.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(unsubscribePaperProduct.fulfilled, (state, action) => {
				state.isLoading = false;

				state.data = state?.data?.filter(
					(item) => item.product.id !== action.payload.response.product_id
				);	
			})
			.addCase(unsubscribePaperProduct.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})

			//unSubscribe resubscribe Strategy
			.addCase(unsubscribeLiveProduct.pending, (state, action) => {
				state.isLoading = true;
			})
			.addCase(unsubscribeLiveProduct.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = state?.data?.map((item) => {
					if (item.product.id === action.payload.response.product_id) {
						return {
							...item,
							is_auto_renewed: !item.is_auto_renewed,
						};
					} else {
						return item;
					}
				});
			})
			.addCase(unsubscribeLiveProduct.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

export default PurchasedProductListSlice.reducer;