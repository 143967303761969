import { createAsyncThunk } from "@reduxjs/toolkit";
import { POST_GOOGLE_LOGIN } from "../../action-types/actions";
import axios from "axios";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";

export const postGoogleLogin = createAsyncThunk(
  POST_GOOGLE_LOGIN.post_google_login,
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${APP_PATH.google_login}`,
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Google login failed:", error);
      toast.error("Google login failed. Please try again.");
      throw error.response?.data || new Error(error.message);
    }
  }
);
