import { createAsyncThunk } from "@reduxjs/toolkit";
import { USER_DETAILS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const fetchUserDetails = createAsyncThunk(
  USER_DETAILS.user_details,
  async () => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_API_URL + APP_PATH.user_details}`
      );
      return response.data.response;
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  }
);
