import { createSlice } from "@reduxjs/toolkit";
import {
  getPricingPlanList,
  createPlanSubscription,
  subscriptionPaymentSuccess,
  updateSubscriptionPlan,
} from "../../reducer/pricing-plan/pricingPlanReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const pricingPlanSlice = createSlice({
  name: "pricingPlan",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get PlanList
      .addCase(getPricingPlanList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPricingPlanList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getPricingPlanList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // create subscription
      .addCase(createPlanSubscription.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createPlanSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createPlanSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // get success subscription
      .addCase(subscriptionPaymentSuccess.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(subscriptionPaymentSuccess.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(subscriptionPaymentSuccess.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      // update plan subscription
      .addCase(updateSubscriptionPlan.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateSubscriptionPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.data = state.data.map((item) => {
        //   if (item.id === action?.payload?.product) {
        //     return {
        //       ...item,
        //       is_auto_renewed: action?.is_auto_renewed,
        //     };
        //   } else {
        //     return item;
        //   }
        // });
      })
      .addCase(updateSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default pricingPlanSlice.reducer;
