import { createSlice } from "@reduxjs/toolkit";
import { brokerConnected } from "../../reducer/broker_connected/brokerConnectedReducer";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const brokerConnectedSlice = createSlice({
  name: "brokerConnected",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(brokerConnected.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(brokerConnected.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(brokerConnected.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default brokerConnectedSlice.reducer;
