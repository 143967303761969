import { createAsyncThunk } from "@reduxjs/toolkit";
import { RESEND_OTP_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postResendOtp = createAsyncThunk(
  RESEND_OTP_ACTIONS.post_resend_otp,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.resend_opt,
        payload
      );
      if (response.data.status === 200) {
        toast.success(response.data.response);
      } else if (response.data.status === 500) {
        if (response.data.message.email[0]) {
          toast.error(response.data.message.email[0]);
        }
      }
      return response.data;
    } catch (error) {
      console.error("Error occurred while resending OTP:", error);
      throw error;
    }
  }
);
