import React, { useEffect } from "react";
import { useCookieConsent } from "../../context/CookieConsentContext";
import "./CookieConsentBanner.scss";
import "../../styles/__modal.scss";
import { openModal, closeModal } from "../../utils/Utils";
import { Images } from "../../utils/ConstantImages";
import { CookieConsentIcon } from "../../utils/SVGIcons";

const CookieConsentBanner = ({ setIsCookies }) => {
	useEffect(() => {
		const checkCookies = localStorage.getItem("cookieConsent");
		if (!checkCookies) {
			openModal();
		} else {
			closeModal();
		}
	}, []);
	const { cookieConsent, handleAcceptCookies, handleDeclineCookies } =
		useCookieConsent();

	if (cookieConsent !== null) {
		return null;
	}

	const handleCloseModal = () => {
		handleDeclineCookies();
		setIsCookies(false);
		closeModal();
	};
	return (
		<React.Fragment>
			<div className="modal-overlay"></div>
			<div
				className="contactModal text-center modal fade show modal-with-img-box"
				id="myModal "
				style={{ display: "block" }}
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content modal-content-img">
						<img src={Images?.Cookies} alt="cookies" />
						<br />
						<h4 className=" contact_modalTitle mb-2 mt-2">Our Cookie Policy</h4>
						<div className="modal-body modal-img">
							<p className="banner-text">
								By clicking the 'Accept' button, you agree to our cookie policy.
								This means that you consent to the use of cookies on our website
								for various purposes, such as improving your browsing
								experience, analyzing site traffic, and personalizing content.
								For more information on how we use cookies and your options to
								manage them, please refer to our cookie policy
							</p>
							<br />
							<button
								onClick={() => {
									handleAcceptCookies();
									closeModal();
								}}
								className="btn btn-primary btn-accept"
							>
								Accept
							</button>
							<button
								onClick={() => {
									handleDeclineCookies();
									closeModal();
								}}
								className="btn btn-outline-primary btn-decline"
							>
								Decline
							</button>
						</div>
						<button className="btnClose_modal" onClick={handleCloseModal}>
							<CookieConsentIcon />
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default CookieConsentBanner;
