import { lazy } from "react";


export const RouterPaths = {
    Home: lazy(() => import('../pages/home/Home')),
    Login: lazy(() => import('../pages/login/Login')),
    TermsAndConditions: lazy(() => import('../pages/terms-and-conditions/TermsAndConditions')),
    Dashboard: lazy(() => import('../pages/dashboard/Dashboard')),
    MarketPlace: lazy(() => import('../pages/market-place/MarketPlace')),
    PurchasedStrategy:lazy(() => import('../pages/purchased-strategy/PurchasedStrategy')),
    TradingPlans:lazy(() => import('../pages/trading-plans/TradingPlans')),
    MailVerification: lazy(() => import('../pages/verification/MailVerification')),
    Pricing: lazy(() => import('../pages/pricing/Pricing')),
    Broker: lazy(() => import('../pages/broker/Broker')),
    RegisterAccount: lazy(() => import('../pages/register-account/RegisterAccount')),
    SetupAccount: lazy(() => import('../pages/setup-account/SetupAccount')),
    GoogleSetupAccount: lazy(() => import('../pages/google-setup-account/GoogleSetupAccount')),
    FAQ: lazy(() => import('../pages/faq/FAQ')),
    ContactUs: lazy(() => import('../pages/contact-us/ContactUs')),
    AboutUs: lazy(() => import('../pages/about-us/AboutUs')),
    ResetPassword: lazy(() => import('../pages/reset-password/ResetPassword')),
    ForgotPassword: lazy(() => import('../pages/forgot-password/ForgotPassword')),
    Features: lazy(() => import('../pages/features/Features')),
    TradeGuru: lazy(() => import('../pages/trade-guru/TradeGuru')),
    Traders: lazy(() => import('../pages/traders/Traders')),
    AccountSettings: lazy(() => import('../pages/account-settings/AccountSettings')),
    TwoFactorOtp: lazy(()=> import('../pages/two-factor-otp/TwoFactorOtp')),
    PrivacyPolicy: lazy(() => import('../pages/privacy-policy/PrivacyPolicy')),
    ChooseBroker: lazy(() => import('../pages/choose-broker/ChooseBroker')),
    ReviewBrokerDisclaimer: lazy(() => import('../pages/review-broker-disclaimer/ReviewBrokerDisclaimer')),
    ChooseLivePaperAccount: lazy(() => import('../pages/choose-live-paper-account/ChooseLivePaperAccount')),
    CurrentBrokerAccount: lazy(() => import('../pages/current-broker-account/CurrentBrokerAccount')),
    ProvideCredential:lazy(()=>import('../pages/provide-credentials/ProvideCredentials')),
    DashboardTermsConditions:lazy(()=>import('../pages/dashboard-terms-conditions/DashboardTermsConditions')),
    DashboardPrivacyPolicy:lazy(()=>import('../pages/dashboard-privacy-policy/DashboardPrivacyPolicy')),
    ManagedStrategies:lazy(()=>import('../pages/managed-strategies/ManagedStrategies')),
    NotFound: lazy(() => import('../pages/not-found-404/NotFound')),
    PricingPlans: lazy(()=>import("../pages/pricing-plans/PricingPlans")),
    TradeHistory: lazy(()=>import("../pages/trade-history/TradeHistory")),
    PricingPlanPayment: lazy(()=>import("../pages/pricing-plan-payment/PricingPlanPayment")),
    SetToken: lazy(()=>import("../pages/set-token/SetToken")),
    ProfileSettings: lazy(()=>import("../pages/profile-settings/ProfileSettings")),
    ChangePassword: lazy(()=>import("../pages/change-password/ChangePassword")),
    ViewConnectedBrokerDetails: lazy(()=>import("../pages/view-connected-broker-details/ViewConnectedBrokerDetails")),
    ViewTradingPlanSummary: lazy(()=>import("../pages/view-trading-plan-summary/ViewTradingPlanSummary")),
}