import { createSlice } from "@reduxjs/toolkit";
import { postResetPassword } from "../../reducer/reset-password/resetPasswordReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postResetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postResetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action;
      })
      .addCase(postResetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// export const { Istoasterloader } = setupAccountSlice.actions;
export default resetPasswordSlice.reducer;
