import { createSlice } from "@reduxjs/toolkit";
import { deleteTradingPlan, getTradingPlanUserList, tradingPlanStatus } from "../../reducer/trading-plan/tradingPlanReducer";

// Default value
const initialState = {
	data: [],
	isLoading: false,
	error: null,
};

const tradingPlanSlice = createSlice({
	name: "tradingPlan",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			// Get Operation
			.addCase(getTradingPlanUserList.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getTradingPlanUserList.fulfilled, (state, action) => {
				state.isLoading = false;
				state.data = action.payload;
			})
			.addCase(getTradingPlanUserList.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})

			// Delete Operation
			.addCase(deleteTradingPlan.pending, (state) => {
				state.isLoading = false;
			})
			.addCase(deleteTradingPlan.fulfilled, (state, action) => {
				state.isLoading = false;
				const deletedId = action.payload.id;

				// Filter out the trade plans
				state.data = state.data
					.map(plan => {
						const filteredTradePlanData = plan.trade_plan_data.filter(data => data.id !== deletedId);

						// If there are no remaining trade plans, return null to indicate removal of the product
						if (filteredTradePlanData.length === 0) {
							return null;
						}

						// Return the updated plan with filtered trade_plan_data
						return {
							...plan,
							trade_plan_data: filteredTradePlanData
						};
					})
					.filter(plan => plan !== null); // Filter out nulls to remove entire products with no trade plans left
			})
			.addCase(deleteTradingPlan.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			})

			// Trading Plan status
			.addCase(tradingPlanStatus.pending, (state) => {
				// state.isLoading = true;
			})

			.addCase(tradingPlanStatus.fulfilled, (state, action) => {
				// state.isLoading = false;

				state.data = state.data.map((item) => {
					// Use a return statement for item
					return {
						...item,
						trade_plan_data: item?.trade_plan_data?.map((it) => {
							// Return the modified it object
							if (it.id === action?.payload?.id) {
								return { ...it, is_enabled: !it.is_enabled }; // Return modified it
							}
							return it; // Return it unmodified if it doesn't match
						})
					};
				});
			})

			.addCase(tradingPlanStatus.rejected, (state, action) => {
				// state.isLoading = false;
				state.error = action.error.message;
			})
	},
});

export default tradingPlanSlice.reducer;
