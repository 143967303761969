import axios from "axios";
import useRefreshToken from "./useRefreshToken";

const axiosPrivate = axios.create();

const useAxiosPrivate = async () => {
  const refresh = useRefreshToken();
  const access_token = sessionStorage.getItem("usr_1a2b3c");
  const refresh_token = sessionStorage.getItem("usr_4d5e6f");

  axiosPrivate.interceptors.request.use(
    (config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = `Bearer ${access_token}`;
        config.headers["User-Refresh-Token"] = `${refresh_token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosPrivate.interceptors.response.use(
    async (response) => {
      const originalRequest = response?.config;
      if (response.data.status === 401 && !originalRequest?.sent) {
        const newAccessToken = await refresh();
        if (newAccessToken) {
         sessionStorage.setItem("usr_1a2b3c", newAccessToken);
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          originalRequest.headers["User-Refresh-Token"] = `${refresh_token}`;
          return axiosPrivate(originalRequest);    
        }
      } else {
        return response;
      }
    },

    async (error) => {
      console.log("error:", error);
      return Promise.reject(error);
    }
  );

  return axiosPrivate;
};

export default useAxiosPrivate;