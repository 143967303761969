import { createSlice } from "@reduxjs/toolkit";
import { postForgotPassword } from "../../reducer/forgot-password/forgotPasswordReducer";

//default value
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const ForgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postForgotPassword.pending, (state, action) => {
        state.isLoading = true;
        state.emailSubmitted = false;
      })
      .addCase(postForgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        if (action.payload.status === 200) {
          state.emailSubmitted = true;
        }
      })
      .addCase(postForgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.emailSubmitted = false;
      });
  },
});

// export const { Istoasterloader } = setupAccountSlice.actions;
export default ForgotPasswordSlice.reducer;
