import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACTION_BROKER_CALLBACK } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const brokerCallback = createAsyncThunk(
  ACTION_BROKER_CALLBACK.broker_callback,
  async (data) => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + APP_PATH.broker_callback + `?${data}`
      );
      if (response.data.status === 500) {
        return response.data.message;
      }
      return response.data.response;
    } catch (error) {
      console.error("Error in broker Callback:", error);
      toast.error("Something went wrong");
      throw error;
    }
  }
);
export const clearBrokerCallback = createAsyncThunk(
  ACTION_BROKER_CALLBACK.clear_broker_callBack,
  () => {
    return {};
  }
);
