import { createAsyncThunk } from "@reduxjs/toolkit";
import { TWO_FACTOR_ACTIONS } from "../../action-types/actions";
import axios from "axios";
import { APP_PATH } from "../../api/backendPath";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export const twoFactorVerify = createAsyncThunk(
  TWO_FACTOR_ACTIONS.two_factor_verify,
  async ({ access_token, otp, refresh_token }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${APP_PATH.two_factor_verify}${otp}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "User-Refresh-Token": `${refresh_token}`,
          },
        }
        );
        
      if (response?.data?.status === 200) {
        toast.success(response?.data?.response?.messages);
      } else if (response?.data?.status === 401) {
        toast.error(response.data.message.detail);
      } else if (response?.data?.status === 500) {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching two factor verify:", error);
      throw error;
    }
  }
);

export const generateBackupCodes = createAsyncThunk(
  TWO_FACTOR_ACTIONS.generate_backup_codes,
  async ({backupCodeOtp}) => {
    const axiosPrivate = await useAxiosPrivate();
    try {
      const response = await axiosPrivate.post(
        `${process.env.REACT_APP_API_URL}${APP_PATH.generate_backup_codes}${backupCodeOtp}`,
        {}
        );
      if(response.data.status=== 200){
        toast.success(response.data.response.message)
        return response.data;
      }else if(response.data.status === 500){
        toast.error(response?.data?.message)
      }
    } catch (error) {
      console.error("Error in generate backup codes:", error);
      toast.error("Something went wrong");
    }
  }
);

export const getUnusedBackupCode = createAsyncThunk(
  TWO_FACTOR_ACTIONS.get_unused_bachup_codes,
  async () => {
    try {
      const axiosPrivate = await useAxiosPrivate();
      const response = await axiosPrivate.get(
        process.env.REACT_APP_API_URL + APP_PATH.unused_backup_codes
      );
      return response.data;
    } catch (error) {
      console.error("Error in get backup code:", error);
      toast.error("Something went wrong");
    }
  }
);