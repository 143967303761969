import { createSlice } from "@reduxjs/toolkit";
import {twoFactorDisabled, twoFactorEnabled, twoFactorLogin } from "../../reducer/two-factor-authentication/twoFactorAuthenticationReducer";

//default value
const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const twoFactorAuthenticationSlice = createSlice({
  name: "twoFactorEnabled",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get Operation
      .addCase(twoFactorEnabled.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(twoFactorEnabled.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(twoFactorEnabled.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //two factor disabled
      .addCase(twoFactorDisabled.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(twoFactorDisabled.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(twoFactorDisabled.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //two-factor login
      .addCase(twoFactorLogin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(twoFactorLogin.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(twoFactorLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

  },
});

export default twoFactorAuthenticationSlice.reducer;
