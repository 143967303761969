import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN_ACTIONS } from "../../action-types/actions";
import { APP_PATH } from "../../api/backendPath";
import axios from "axios";
import { toast } from "react-toastify";

export const postLogin = createAsyncThunk(
  LOGIN_ACTIONS.post_login,
  async (payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + APP_PATH.login,
        payload
      );
      if (response.data.status === 200) {
        return response.data.response;
      } else if (response.data.status === 500) {
        const { message } = response.data;
        if (message.non_field_errors) {
          toast.error(message.non_field_errors[0]);
        } else if (message.email) {
          toast.error(message.email[0]);
        } else if (message.password) {
          toast.error(message.password[0]);
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      } else if (response.data.status === 401) {
        toast.error(response.data.message.detail);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    } catch (error) {
      toast(error.data.message);
      console.log("Error", error.data.message);
    }
  }
);
